import { Component, OnInit } from '@angular/core';
import { OrganisationService } from './../../shared/organisation.service';
import { UserService } from './../../shared/user.service';
import { User } from '../../shared/user.model';
import { Router } from '@angular/router';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { environment } from '../../../environments/environment';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';


const URL = environment.apiBaseUrl + '/organisation/upload';

@Component({
  selector: 'app-upload-identity',
  templateUrl: './upload-identity.component.html',
  styleUrls: ['./upload-identity.component.css']
})
export class UploadIdentityComponent implements OnInit {

  isIdentityFileValide: Boolean;
  public uploaderVerso: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });
  public uploaderRecto: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });
  userDetails: User;
  errorMsg;
  file01Uploaded;
  file02Uploaded;
  constructor(private userService: UserService,
              private organisationService: OrganisationService,
              private router: Router) { }

  ngOnInit() {
    // this.startTimer();
    this.uploaderVerso.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploaderRecto.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.userService.getUserProfile().subscribe(
      res => {
        this.userDetails = res['user'];
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  onSubmit(form: NgForm) {
    const idCardVerso = this.uploaderVerso.queue[this.uploaderVerso.queue.length - 1];
    const idCardRecto = this.uploaderRecto.queue[this.uploaderRecto.queue.length - 1];
    // if scan card verso is defined
    if ( (idCardVerso === undefined || idCardVerso === null) || (idCardRecto === undefined || idCardRecto === null) ) {
        this.isIdentityFileValide = false;
        setTimeout( () => {
          this.isIdentityFileValide = true;
          }, 3000);
    } else {

        idCardVerso.file.name = 'v' + this.userDetails._id;
        idCardRecto.file.name = 'r' + this.userDetails._id;
        // Upload scan Card Verso
        this.uploaderVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        this.file01Uploaded = responseObject.success;

        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
       };
      this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        const responseObject = JSON.parse(response);
        const objectControl = {
          identityControl : true,
          idOrganisation : this.userDetails.idOrganisation
        };
        this.organisationService.updateControlIdentity(objectControl).subscribe(
          res => {
            this.file02Uploaded = responseObject.success;
            setTimeout( () => {
              this.router.navigateByUrl('/dashboard');
            }, 3000);
          },
          err => {
            this.errorMsg = true;
          }
        );
        // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
      };
      this.uploaderRecto.uploadItem(idCardRecto);
      this.uploaderVerso.uploadItem(idCardVerso);


    }
    // if scan card recto is defined
    // if (idCardRecto) {
    //   idCardRecto.file.name = 'r' + this.userDetails._id;
    //   // Upload scan Card Recto
    //   this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //     // console.log('ImageUpload:uploaded:', item, status, response);
    //     alert('File uploaded successfully');
    //   };
    //   this.uploaderRecto.uploadItem(idCardRecto);
    // }
  }






}
