import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TempDataService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  saveTempDataOrganization(id,oData) {
    return this.http.post(environment.apiBaseUrl + '/organisation/tempdata/' + id,oData);
  }

  getTempDataOrganization(id) {
    return this.http.get(environment.apiBaseUrl + '/organisation/tempdata/' + id);
  }


}
