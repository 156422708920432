import { Component, OnInit,ViewChild,ViewEncapsulation } from '@angular/core';
import { CampaignService } from './../../../shared/campaign.service';
import { Campaign } from './../../../shared/campaign.model';
import { PagerService } from '../../../shared/pager.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserService } from './../../../shared/user.service';
import {MatTableDataSource} from '@angular/material/table';
import { ShareDataService } from '../../../shared/shareData.service';
import { first } from 'rxjs/operators';

export interface CampaignElement {
  Statut: string;
  actif : string;
  created: Date;
  title: string;
  transacCampaing: [];
  amountTotal : number;
  Action:any ;
}
const ELEMENT_DATA: CampaignElement[] = [];



@Component({
  selector: 'app-listcampaign',
  templateUrl: './listcampaign.component.html',
  styleUrls: ['./listcampaign.component.css'],
  encapsulation: ViewEncapsulation.None
})




export class ListcampaignComponent implements OnInit {
  idOrganization: String;
  isLoading : Boolean = false;
  filterState: string = "encours";
  filterStateTxt : string = "En cours";
  filterStateCampaign: object[] = [{ "id": 'encours', text: "En cours" }, { id: 'archive', text: "Archivé" }, { id: 'all', text: "Tous" }];
  campaignArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listCampaign: Boolean;
  currentCampaign: Campaign;
  errorMsg: Boolean;
  idCampaign: String;
  amountTotalCampaign : Number = 0;
  displayedColumns: string[] = ['col1', 'col2', 'col3', 'col4','col5','col6','col7'];
  dataSource = new MatTableDataSource<CampaignElement>(ELEMENT_DATA);

  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }
  getTotalCost() {
   return this.campaignArray.map(t => t.amountTotal).reduce((acc, value) => acc + value, 0);
  }

  constructor(private userService: UserService,
    private campaignService: CampaignService,
    private pagerService: PagerService,
    private shareDataService:ShareDataService,
    private router: Router) {
    this.currentCampaign = new Campaign();
  }

  

  redirectSelectOrga(){
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }
    getIdOrganisation(){
       
      this.selectedOrga = this.shareDataService.getItems();
      if (this.selectedOrga !== undefined && this.selectedOrga !== null){
        if(this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null ){
          if(this.selectedOrga.idOrganisation === ""){
            this.redirectSelectOrga();
          }else{
            return this.selectedOrga.idOrganisation;
          }
         
        }
  
      }
  
      return "";
    }


  // @ViewChild(MatPaginator, {static: any}) paginator: MatPaginator;
  ngOnInit() {

    //this.dataSource.paginator = this.paginator;
   

    this.shareDataService.currentMessage.subscribe(selectOrga => this.selectedOrga = selectOrga);
    this.idOrganization = this.getIdOrganisation();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.idCampaign =  this.idOrganization ;
        this.list(this.idCampaign);
      },
      err => {
        console.log(err);
      }
    );
  }

  onFilterStateCampaign(oEvent) {

    var filter = oEvent.value;
    var search = this.filterStateCampaign.find(elemnt => elemnt['id'] === filter);
    if (search !== undefined && search !== null){
      this.filterStateTxt = search['text'];
    }
    this.filterState = filter;
    this.setBusy(true);
    this.list(this.idCampaign);
  }

  setBusy(flag){

    this.isLoading = flag;

  }

  calculAmount(listCampaign) {
    var amoutTotal = 0;
    listCampaign.forEach(element1 => {
      element1.amountTotal = 0;
      element1.feeTotal = 0;
      element1.netTotal = 0;
      element1.transacCampaing.forEach(element2 => {
        if (!isNaN(element2.amount))
          element1.amountTotal = element2.amount + element1.amountTotal;
        if (!isNaN(element2.fee))
          element1.feeTotal = element2.fee + element1.feeTotal;
        if (!isNaN(element2.net))
          element1.netTotal = element2.net + element1.netTotal;

      });
    });

    return listCampaign;
  }

  list(id) {
    var that = this;
    this.campaignService.getCampaignOrganization(id,this.filterState).subscribe(
      res => {
        console.log(res);
        this.campaignArray = res['campaigns'];
        this.allItems = res['campaigns'];
        this.allItems = that.calculAmount(this.allItems);
        that.amountTotalCampaign = 0;
        this.allItems.forEach(element => {
        that.amountTotalCampaign += element.amountTotal;
        });
        if (this.allItems.length !== 0) {
          this.listCampaign = true;

        } else {
          this.listCampaign = false;
        }
        this.setBusy(false);
        this.setPage(1);
      },
      err => {
        console.log(err);
      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    this.dataSource = new MatTableDataSource<CampaignElement>(this.allItems);
  }

  searchCampaign(searchValue: string) {
    const campaignArray = this.campaignArray.filter(x => x.title.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = campaignArray;
    this.setPage(1);
  }

  selectCurrentCampaign(campaign) {
    this.currentCampaign = campaign;
  }

  confirmArchiveCampaign(flag) {

    const campaign = {
      _id: this.currentCampaign._id,
      archive: flag
    };

    this.campaignService.updateArchive(campaign).subscribe(
      res => {
        this.ngOnInit();
      },
      err => {

      });
  }

  btnNavToUpdate(campaing) {
    if (campaing !== undefined && campaing !== null) {

      //if (campaing.editable === true) {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            '_id': campaing._id,
            // 'title': campaing.title,
            // 'description': campaing.description,
            // 'icon': campaing.icon,
            // 'actif': campaing.actif
          }
        };

        this.router.navigate(['/dashboard/campaign/update'], navigationExtras);
      //}
    }
    return false;
  }

  updateStatusCampaign(status) {
    const campaign = {
      _id: this.currentCampaign._id,
      status: status
    };
    this.campaignService.updateStatus(campaign).subscribe(
      res => {
        this.ngOnInit();
      },
      err => {
        this.errorMsg = true;
      });
  }
}

