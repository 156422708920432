export class Whitelist{
  id : String;
 uuid: String;
 mac: String;
 idOrganization: String;
 active: String;
 activeDate: Date;
dateFrom : Date;
dateTo : Date;
MerchantIdentifier : String;
MerchantSecretKey: String;
AccountId : String;
LocationId : String;
location : String;
}
