import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KioskService } from './../../../shared/kiosk.service';
import { OrganisationService } from './../../../shared/organisation.service';
import { Kiosk } from './../../../shared/kiosk.model';
import { Organisation } from './../../../shared/organisation.model';

@Component({
  selector: 'app-list-kiosk-organisation-admin',
  templateUrl: './list-kiosk-organisation-admin.component.html',
  styleUrls: ['./list-kiosk-organisation-admin.component.css']
})
export class ListKioskOrganisationAdminComponent implements OnInit {

  kiosks: Kiosk[];
  currentKiosk: Kiosk;
  isListKiosks: Boolean = false;
  isListKiosk = true;
  organisation: Organisation;


  public constructor(private route: ActivatedRoute, private kioskService: KioskService, private organisationService: OrganisationService) {}

  ngOnInit() {
    // console.log(this.route.snapshot.paramMap.get('id'));
    this.organisationService.getOrganisationById(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
          this.organisation = res['organisation'];
      },
      err => {

      }
    );
    // this.kioskService.getKioksByOrganisation(this.route.snapshot.paramMap.get('id')).subscribe(
    //   res => {
    //     this.kiosks = res['kiosks'];
    //     console.log(this.kiosks);
    //     if ( this.kiosks.length !== 0 ) {
    //          this.isListKiosks = true;
    //     }
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );
  }

  detailKiosk(kiosk) {
    kiosk.lastCheck = this.formatDate(new Date(kiosk.lastCheck));
    kiosk.installationDate = this.formatDate(new Date(kiosk.installationDate));
    this.currentKiosk = kiosk;
    this.isListKiosk = false;
  }

  showlistKiosk() {
    this.isListKiosk = true;
  }

  formatDate(date) {
    const days = date.getDate();
    const month = date.getMonth() + 1; // Be careful! January is 0 not 1
    const year = date.getFullYear();
    if (month < 10) {
      return '0' + days + '-0' + month + '-' + year;
    } else {
      return days + '-' + month + '-' + year;
    }
}

}
