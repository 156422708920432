export class StripeTransaction {
  _id: String;
  idStripe: String;
  idTransaction: String;
  email: String;
  amount: Number;
  fee: String;
  net: String;
  idcampaign: String;
  idOrganisation: String;
  date: String;

}
