import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';

export interface FormModel {
  captcha?: string;
}
@Component({
  selector: 'app-init-password',
  templateUrl: './init-password.component.html',
  styleUrls: ['./init-password.component.css']
})
export class InitPasswordComponent implements OnInit {
  recaptcha : any[];
  public formModel: FormModel = {};
  model = {
    email: ''
  };
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(private userService: UserService, private router: Router) { }
  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  ngOnDestroy(){
   
  }
  ngOnInit() {
   
    if (this.userService.isLoggedIn())
        this.router.navigateByUrl('/dashboard');
  }

  onSubmit(form: NgForm) {

    this.userService.initPasswordUser(form.value.email).subscribe(
      res => {
        if (res['status'] === true) {
          this.router.navigateByUrl('/login')
        } else {
          //
        }
      },
      err => {

      }
    );

  }



}
