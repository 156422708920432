import {Component, ViewChild,ElementRef, OnInit} from '@angular/core';
import { OrganisationService } from '../../shared/organisation.service';
import { ShareDataService } from '../../shared/shareData.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/map';

declare var $;
@Component({
  selector: 'app-manage-stripeaccount',
  templateUrl: './manage-stripeaccount.component.html',
  styleUrls: ['./manage-stripeaccount.component.css']
})
export class ManageStripeaccountComponent implements OnInit {

   ui : any = {
     buttonDestroy : {
       visible : false
     },
     destroy : ""
   }
  dataOrga = [];
  dtTrigger: Subject<any> = new Subject<any>();
  currentItem : any = {};
  constructor(
    private organisationService: OrganisationService,
    private shareDataService:ShareDataService

  ) { }

  ngOnInit(): void {
       // this.dataTable = $(this.table.nativeElement);
    // this.dataTable.DataTable(this.dtOption);
    this.getOrganizationList();
  }
  getOrganizationList() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {
        console.log(res);

        this.formatListOrganisation(res['organisation']);
        this.dtTrigger.next();
      },
      err => {
       
        console.log(err);
      }

    );
  }
  filterById(): void {
    
  }
  formatListOrganisation(resData){
    var that = this;
    that.dataOrga = [];   


    if(resData !== undefined && resData !== null){
      if(Array.isArray(resData)){
        resData.forEach(elem => {
          var elemData = {
            id  : elem._id,
            id_connected_account  : elem.id_connected_account,
            organisationName : elem.organisationName

          };

          that.dataOrga.push(elemData);
        });


      }
    }
  }
  selectCurrentItem(organisation) {
    this.currentItem = organisation;
  }

  onDestroyChange(searchValue: string): void {  
    console.log(searchValue);

    if(searchValue === "DESTROY"){     
      this.ui.buttonDestroy.visible = true;
    }else{
      this.ui.buttonDestroy.visible = false;
    }
  }

  setPageInfoRNA(bflag) {
    if (bflag === false)
      $('#sendReceiptCampaign').modal('hide');
    else {
      $('#sendReceiptCampaign').modal('show');
    }
  }

  deleteStripeAccount(){

    this.ui.buttonDestroy.visible = false;
    this.ui.destroy = "";

    this.organisationService.deleteStripeAccount(this.currentItem.id_connected_account,this.currentItem.organisationName).subscribe(
      res => {
      this.setPageInfoRNA(false);
      },
      err => {
       
        console.log(err);
      }

    );
  }
 
}
