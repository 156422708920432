

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  getTransactionByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/transaction/organization/' + id);
  }

  getSumTransactionByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/sum', {
      idOrganisation: id
    });
  }

  getNbDonorByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/donor/total', {
      idOrganisation: id
    });
  }

  getNbTransactionPerDayByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/day/sum', {
      idOrganisation: id
    });
  }

  getSumTransactionPerDayByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/day/sum', {
      idOrganisation: id
    });
  }

  getSumTransactionPerMonthByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/month/sum', {
      idOrganisation: id
    });
  }

  getLastDateTransactionByOrganisation(id) {
    return this.http.post(environment.apiBaseUrl + '/transaction/organization/date/last', {
      idOrganisation: id
    });
  }

sendReceiptTransaction(transaction) {
  if (transaction !== undefined && transaction !== null){
    var url = environment.apiBaseUrl + '/transaction/organization/send/receipt/' + transaction.idStripe;
    return this.http.post(url, transaction);
  }
 
}


getTransactionById(id) {
  return this.http.get(environment.apiBaseUrl + '/transaction/' + id);
}

getTransactionsByCampaign(id) {
  return this.http.get(environment.apiBaseUrl + '/transactions/campaign/' + id);
}




}
