export class Campaign {
  _id: String;
  idCampaign: String;
  icon: String;
  title: String;
  description: String;
  created: string;
  actif: Boolean;
  collected: any;
  editable : Boolean;
  idOrganization : String;
  idDeviceList : [];
  idKioskList : [];
}
