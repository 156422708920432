export const environment = {
    production: true,
    //apiBaseUrl: 'http://localhost:3002/api',
    //apiBaseUrl2: 'http://localhost:3002/apikpi'
    //apiBaseUrl: 'http://46.101.202.225:3002/api'
    apiBaseUrl: '/api',
    apiBaseUrl2: '/apikpi',
    apiBaseUrlAdmin: '/apiadmin',
    apiBaseUrlDevice: '/apidevice',
  
  };
  