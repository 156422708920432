import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { OrganisationService } from '../shared/organisation.service';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from '../shared/user.model';
import { Router } from '@angular/router';
import { Role } from "../shared/role";
// import { containsElement } from '@angular/animations/browser/src/render/shared';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  // registerForm: FormGroup;
  userDetails: User;
  // isAccountDetailOpen: boolean;
  // loading = true;
  // For the date input on the form validation account
  // maxDate = '';
  // datetime: Date;
  // maxDateYear: number;
  // accountDetails =
  //   {
  //     organisationName: '',
  //     business_type: 'individual',
  //     first_name: '',
  //     last_name: '',
  //     phone: '',
  //     dobindividual: '',
  //     dob: {
  //       day: '00',
  //       month: '00',
  //       year: '0000',
  //     },
  //     address: {
  //       city: '',
  //       line1: '',
  //       postal_code: '',
  //       state: ''

  //     },
  //     rib: '',
  //     confirmRib: '',
  //     description: ''
  //   };


  constructor(private userService: UserService, private organisationService: OrganisationService, private router: Router) { }

  get isAdmin() {
    return this.userDetails && this.userDetails.role2 === Role.UserDashboard;
  }

  checkIsAdmin(aRoles) {
    if (Array.isArray(aRoles)) {
      var found = aRoles.find(element => (element.name === Role.AdminDashboard || element.name === Role.UserDashboard));
      if (found !== undefined) {
        return true;
      }
    }
    return false;
  }

  ngOnInit() {

    // Get user profil information
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.userDetails = res['user'];

        this.userDetails = res['user'];
        if (res['user'] !== undefined && res['user'].role !== undefined) {
          if (!this.checkIsAdmin(res['user'].role)) {
            this.userService.deleteToken();
            this.router.navigate(['/login']);

          } else {
            if (res['user'].accountDetailActive === false) {
              this.router.navigateByUrl('/account/details');
            }
          }
        }

        // if (this.userDetails.role[0].name === 'adminOrganisation') {
        //   if (res['user'].accountDetailActive === false) {
        //     this.router.navigateByUrl('/account/details');
        //   }
        // } else {
        //   this.userService.deleteToken();
        //   this.router.navigate(['/login']);
        // }
      },
      err => {
        // console.log(err);
      }
    );



    //     setTimeout(() => {    //<<<---    using ()=> syntax
    //       this.loading = false;
    //  }, 3000);




    // Initialisation Date for date of birth creation account
    // const dateTime = new Date();
    // this.datetime = new Date();
    // const date = dateTime.getDate();
    // const month = dateTime.getMonth() + 1; // Be careful! January is 0 not 1
    // const year = dateTime.getFullYear() - 18;
    // this.maxDateYear = dateTime.getFullYear() - 18;
    // if (month < 10) {
    //   this.maxDate = year + '-0' + month + '-' + date;
    // } else {
    //   this.maxDate = year + '-' + month + '-' + date;
    // }

  }



  // onSubmit(form: NgForm) {

  //   const dobindividual = new Date(form.value.dobindividual);
  //   const yearIndividual = dobindividual.getFullYear();

  //   if (yearIndividual > this.maxDateYear) {
  //     return;
  //   }
  //   if (form.value.rib !== form.value.confirmRib) {
  //     console.log('erreur rib incorret');
  //     return;
  //   }

  //   form.value.dobindividual = dobindividual;
  //   form.value.user = this.userDetails;

  //   this.organisationService.postOrganisation(form.value).subscribe(
  //     res => {
  //       // this.showSucessMessage = true;
  //       // setTimeout(() => this.showSucessMessage = false, 4000);
  //       form.resetForm();
  //       this.userDetails.accountDetailActive = true;
  //     },
  //     err => {
  //       if (err.status === 404) {
  //         console.log(err);
  //         return 'Something went wrong';
  //         // this.serverErrorMessages = err.error.join('<br/>');
  //       } else {
  //         return 'Something went wrong.Please contact admin.';
  //       }
  //       // this.serverErrorMessages = 'Something went wrong.Please contact admin.';
  //     }
  //   );
  // }

}
