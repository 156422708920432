import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { OrganisationService } from './../../../shared/organisation.service';
import { Organisation } from './../../../shared/organisation.model';
import { User } from './../../../shared/user.model';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { AngularIbanModule } from 'angular-iban';

const URL = 'http://localhost:3002/api/organisation/upload';

@Component({
  selector: 'app-update-organisation-admin',
  templateUrl: './update-organisation-admin.component.html',
  styleUrls: ['./update-organisation-admin.component.css']
})

export class UpdateOrganisationAdminComponent implements OnInit {

  organisation: Organisation;
  user: User;
  form: FormGroup;
  fb: FormBuilder;
  iban: AngularIbanModule;
  valueIban: String;
  confirmRib: '';
  testIban: '';
  isSameIban: Boolean;

  linkDocument01 = '';
  linkDocument02 = '';
  isIdentityFileValide: Boolean;

  statusUpdatedGood: Boolean = false;
  statusUpdatedAlert: Boolean = false;


  public uploaderVerso: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });
  public uploaderRecto: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo' });

  constructor(private organisationService: OrganisationService, private route: ActivatedRoute, fb: FormBuilder,
              private angularIbanModule: AngularIbanModule) {

      this.form = fb.group({
            id: [''],
            organisationName: ['', Validators.required],
            description: ['', Validators.required],
            city: ['', Validators.required],
            line1: ['', Validators.required],
            state: ['', Validators.required],
            postal_code: ['', Validators.required],
            iban: ['', Validators.required],
            accountStripe: [''],
            // location: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            phone: ['', Validators.required],
            dobindividual: ['', Validators.required],

            });
  }




  ngOnInit() {
    this.getUserByOrganisation(this.route.snapshot.paramMap.get('id'));
    this.uploaderVerso.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // console.log(this.uploaders);
    this.uploaderRecto.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // console.log(this.uploaders);
    this.organisationService.getOrganisationById(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
          this.organisation = res['organisation'];
          let dobindividual = this.formatDate(new Date(res['organisation'].president.dobindividual));
          this.form.controls['id'].setValue(res['organisation']._id);
          this.form.controls['organisationName'].setValue(res['organisation'].organisationName);
          this.form.controls['description'].setValue(res['organisation'].description);
          this.form.controls['city'].setValue(res['organisation'].address.city);
          this.form.controls['state'].setValue(res['organisation'].address.state);
          this.form.controls['line1'].setValue(res['organisation'].address.line1);
          this.form.controls['postal_code'].setValue(res['organisation'].address.postal_code);
          this.valueIban = res['organisation'].iban;
          this.testIban = res['organisation'].iban;
          this.form.controls['iban'].setValue(res['organisation'].iban);
          this.form.controls['accountStripe'].setValue(res['organisation'].accountStripe);
          this.form.controls['first_name'].setValue(res['organisation'].president.first_name);
          this.form.controls['last_name'].setValue(res['organisation'].president.last_name);
          this.form.controls['phone'].setValue(res['organisation'].president.phone);
          this.form.controls['dobindividual'].setValue(dobindividual);
          console.log('je suis dans la link');
          console.log(this.user);
          this.linkDocument01 = 'http://localhost:3002/api/organisation/images/v' + this.user._id + '-.png';
          this.linkDocument02 = 'http://localhost:3002/api/organisation/images/r' + this.user._id + '-.png';
      },
      err => {
          return err;
      }
    );
  }



  onSubmit() {
      /**
       * If the form is valid ( all informations valid )
       */
      if (this.form.status === 'VALID') {
        this.uploadDocument();
        /**
         * Update kiosk by id
         */
        this.organisationService.update(this.form.value).subscribe(
          res => {
            this.statusUpdatedGood = true;
            setTimeout(() => this.statusUpdatedGood = false, 4000);
          },
          err => {
            this.statusUpdatedAlert = true;
            setTimeout(() => this.statusUpdatedAlert = false, 4000);
          }
        );
      }
  }

  formatDate(date) {
    const days = date.getDate();
    const month = date.getMonth() + 1; // Be careful! January is 0 not 1
    const year = date.getFullYear();
    if (month < 10 && days < 10) {
      return year + '-0' + month + '-0' + days;
    } else if (month < 10 ) {
      return year + '-0' + month + '-' + days;
    } else if (days < 10 ) {
      return year + '-' + month + '-0' + days;
    } else {
      return year + '-' + month + '-' + days;
    }
  }

  uploadDocument() {
    const idCardVerso = this.uploaderVerso.queue[this.uploaderVerso.queue.length - 1];
    const idCardRecto = this.uploaderRecto.queue[this.uploaderRecto.queue.length - 1];

    // if scan card verso is defined
    if (idCardVerso === undefined || idCardVerso === null ) {
        this.isIdentityFileValide = false;
    } else {
      idCardVerso.file.name = 'v' + this.user._id;
      // Upload scan Card Verso
      this.uploaderVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      // console.log('ImageUpload:uploaded:', item, status, response);
      // alert('File uploaded successfully');
      this.linkDocument01 = 'http://localhost:3002/api/organisation/images/v' + this.user._id + '-.png';
      };
      //
      this.uploaderVerso.uploadItem(idCardVerso);
    }
    // if scan card recto is defined
    if (idCardRecto) {
      idCardRecto.file.name = 'r' + this.user._id;
      // Upload scan Card Recto
      this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.linkDocument02 = 'http://localhost:3002/api/organisation/images/r' + this.user._id + '-.png';
      // crée un nouvel élément div

      // document.getElementById('document01').remove();
      // console.log('ImageUpload:uploaded:', item, status, response);
        // alert('File uploaded successfully');
      };
      this.uploaderRecto.uploadItem(idCardRecto);
    }
  }

  getUserByOrganisation(id) {
    this.organisationService.getUsersByOrganisationId(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
          this.user = res['user'];
      },
      err => {
          return err;
      }
    );
  }

  // If the both IBAN is same
  isSameIbanFunc() {

    this.confirmRib = this.form.get('iban').value;
    console.log(this.testIban);
    if (this.confirmRib === this.testIban ) {
      this.isSameIban = true;
    } else {
      this.isSameIban = false;
    }
  }



}
