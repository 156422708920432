import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listdevice',
  templateUrl: './listdevice.component.html',
  styleUrls: ['./listdevice.component.css']
})
export class ListdeviceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
