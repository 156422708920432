export class Organisation {
  _id: String;
  organisationName: String;
  description: String;
  address: {
    city: String,
    postal_code: String,
    line1: String,
    state: String,
          };
  president: {
    dobindividual: String,
    first_name: String,
    last_name: String,
    phone: String,
  };
  iban: String;
  accountStripe: {
    id: String,
    email: String,
  };
  user: {
    activeAccountByAdmin: String
  };
  externalAccountStripe: any;
  id_connected_account: any;
  linkDonation: String;
  created: Date;
}
