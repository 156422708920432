import { Component, OnInit } from '@angular/core';
import { SimpleTimer } from 'ng2-simple-timer';
import { UserService } from '../../shared/user.service';
import { OrganisationService } from '../../shared/organisation.service';
import { Organisation } from '../../shared/organisation.model';
import { TransactionService } from '../../shared/transaction.service';
import { formatter } from '../../shared/formatter';
import { Dashboarddata } from '../../shared/dashaboardata';
import { KpiService } from '../../shared/kpi.service';
import { ShareDataService } from '../../shared/shareData.service';
//import * as CanvasJS from '../../../assets/canvasjs.min';
import { StripeTransactionService } from '../../shared/stripeTransaction.service';
import { Router } from '@angular/router';
var _ = require('lodash');
import { CountUp } from "countup.js";

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard-year.component.html',
  styleUrls: ['./home-dashboard-year.component.css']
})
export class HomeDashboardYearComponent implements OnInit {

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;

  nameOrganization: string;

  userDetails = {};
  organisation = {
    externalAccountStripe: false,
  };


  errorServer;
  defExternalAccountStripe;
  defIdentityControl;

  idOrganization: String;

  refreshPage: Boolean = false;

  modelDashboard: any;
  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }

  constructor(private st: SimpleTimer,
    private userService: UserService,
    private organisationService: OrganisationService,
    private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService,
    private kpiService: KpiService,
    private shareDataService: ShareDataService,
    private router: Router) {
    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService, shareDataService);
    this.modelDashboard = this.dashboarddata.modelDashboard;

  }

  redirectSelectOrga() {
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }
  getIdOrganisation() {

    this.selectedOrga = this.shareDataService.getItems();
    this.shareDataService.currentMessage.subscribe(selectOrga => this.selectedOrga = selectOrga);
    if (this.selectedOrga !== undefined && this.selectedOrga !== null) {
      if (this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null) {
        if (this.selectedOrga.idOrganisation === "") {
          this.redirectSelectOrga();
        } else {
          return this.selectedOrga.idOrganisation;
        }
      }
    }
    return "";
  }

  ngOnInit() {

    this.st.delTimer('5sec');
    this.st.newTimer('5sec', 5, true);

    var that = this;
    this.idOrganization = this.getIdOrganisation();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        if (this.checkDashBoard(res)) {
          this.userDetails = res['user'];
          setTimeout(function () { that.callback(); }, Dashboarddata.delay);
          this.timerId = this.st.subscribe('5sec', () => this.callback());
          this.getOrganisationByUserId(this.idOrganization);
          this.organizationDashBoardOpened(this.idOrganization);
        };


      },
      err => {
        this.errorServer = true;
      }
    );
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    // this.loadExternalScript('assets/js/functions.js');
  }

  checkDashBoard(oRes) {
    var that = this;
    var resOrg = oRes['organisation'];
    return true;
    if (resOrg !== undefined && resOrg !== null) {

      if (resOrg.dasboard_open == true) {
        if (resOrg.dasboard_open_count > 0) {
          return true;
        } else {
          this.router.navigateByUrl('/account/details');
          return false;
        }

      } else {
        this.router.navigateByUrl('/account/details');
        return false;
      }
    }

  }

  refresh() {
    this.ngOnInit();
    this.refreshPage = true;
    setTimeout(() => {
      this.refreshPage = null;
    }, 2000);
  }


  organizationDashBoardOpened(id) {

    this.organisationService.organizationDashBoardOpened(id).subscribe(
      res => {

      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        this.nameOrganization = res['organisation'].organisationName;
        this.defExternalAccountStripe = res['organisation'].externalAccountStripe;
        this.defIdentityControl = res['organisation'].identityControl;
      },
      err => {
        this.errorServer = true;
      }
    );
  }


  callback() {
    if (this.userService.isLoggedIn()) {
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
      this.st.delTimer('5sec');
    }
  }



}
