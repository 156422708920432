import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from './user.model';

import { Organisation } from './organisation.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  selectedUser = {
    fullName: '',
    email: '',
    password: ''
    };

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  // constructor(private http: HttpClient) { }
  constructor(private http: HttpClient) { }

  //HttpMethods

  postUser(user: User) {
    return this.http.post(environment.apiBaseUrl + '/register', user, this.noAuthHeader);
  }

  postUserAdmin(user) {
    return this.http.post(environment.apiBaseUrl + '/user/admin/register', user);
  }

  login(authCredentials) {
    return this.http.post(environment.apiBaseUrl + '/authenticateadm', authCredentials, this.noAuthHeader);
  }

  disableAccount(id){
    return this.http.post(environment.apiBaseUrl + '/user/account/disable', id);
  }

  getUserProfile() {
    return this.http.get(environment.apiBaseUrl + '/userProfile',this.noAuthHeader);
    // return this.http.get(environment.apiBaseUrl + '/dashboard');
  }
  getUserProfileAdm() {
    return this.http.get(environment.apiBaseUrl + '/userProfileadm');
    // return this.http.get(environment.apiBaseUrl + '/dashboard');
  }

  getOrganisationByUserId(id) {
    return this.http.get(environment.apiBaseUrl + '/user/organisation/' + id);
  }

  activeUserAccount(id: string) {
    let objectid = {
      confirmId: id
    };
    return this.http.post(environment.apiBaseUrl + '/activeAccount', objectid, this.noAuthHeader);
  }

  updatePassword(objectUser){
     return this.http.post(environment.apiBaseUrl + '/updatepassword', objectUser);
  }

  updatePasswordIdCheck(objectUser : any,id : String){
    return this.http.post(environment.apiBaseUrl + '/user/password/update/'+id, objectUser);
 }

  //Helper Methods

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else {
      return null;
    }
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  initPasswordUser(email) {
    return this.http.post(environment.apiBaseUrl + '/user/password/init', {id: email}, this.noAuthHeader);
  }

  controlIdUser_old(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }

  controlIdUser(id) {
    return this.http.get(environment.apiBaseUrl + '/user/password/control/' + id, this.noAuthHeader);
  }

  getUserList(id) {
    return this.http.get(environment.apiBaseUrl + '/user/list' + id, this.noAuthHeader);
  }

  getUserListAd() {
    return this.http.get(environment.apiBaseUrlAdmin + '/user/list');
  }
  

}
