import { ElementRef } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(private elRef: ElementRef){ 
  
  }
  ngOnInit(){
    $.getScript('../assets/js/functions.js'); //Add path to your custom js file
}

observer;

ngAfterViewInit(){
  this.observer = new MutationObserver(mutations => {
    
    console.log('Dom change detected...');
    $.getScript('../assets/js/functions.js'); //Add path to your custom js file
    $.getScript('../assets/js/components/bs-datatable.js'); //Add path to your custom js file

  
  });
  var config = { attributes: true, childList: true, characterData: true };

  this.observer.observe(this.elRef.nativeElement, config);
}
}
