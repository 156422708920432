export class Kiosk {
    _id: String;
    idKiosk: String;
    type: String;
    description: String;
    lastCheck: String;
    location: String;
    address: {
        city: String,
        postaleCode: String,
        state: String,
        line01: String,
        latitude: String,
        longitude: String,
          };
    located: String;
    supervisor: String;
    installationDate: Date;
    isActive: Boolean;
    idTemplate : String;
    
}
