import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable( {
    providedIn: 'root'
  })
export class ShareDataService {
    isAdmin:Boolean = false;
    constructor(){

    } 
    
    selectedOrga2 :any = {
        idOrganisation: '',
        organisationName: 'Organisation',
        user : {}
    }
    
    private selectedOrga = new BehaviorSubject<any>(this.selectedOrga2);
    currentMessage = this.selectedOrga.asObservable();
    items = [];

    changeMessage(message: any){
        this.selectedOrga.next(message);
        this.isAdmin = true;
    }

  
  
    addToCart(product) {
      this.selectedOrga2 = product;
    }
  
    getisAdmin() {
        return this.isAdmin;
      }
    getItems() {
      return this.selectedOrga2;
    }
  
    clearCart() {
      this.items = [];
      return this.items;
    }
  }