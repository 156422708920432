import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Whitelist } from './whitelist.model';
@Injectable({
  providedIn: 'root'
})
export class WhitelistService {
  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  constructor(private http: HttpClient) { };
  getListAll(){
    return this.http.get(environment.apiBaseUrlDevice + '/whitelist/all');
  }

  getItem(id){
    return this.http.get(environment.apiBaseUrlDevice + '/whitelist/'+id);
  }

  addWhitelist(dataModel){
    return this.http.post(environment.apiBaseUrlDevice + '/whitelist/add',dataModel);
  }
  createLocation(dataModel){
    return this.http.post(environment.apiBaseUrlDevice + '/location/add',dataModel);
  }
  updateWhitelist(id,dataModel){
    return this.http.post(environment.apiBaseUrlDevice + '/whitelist/add',dataModel);
  }

  removeWhitelist(id){
    return this.http.delete(environment.apiBaseUrlDevice + '/whitelist/remove/'+id);

  }


}
