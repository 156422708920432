import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, Form } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { PasswordValidation } from '../../tools/PasswordValidation';
import { ActivatedRoute } from '@angular/router';
export interface FormModel {
  captcha?: string;
}
@Component({
  selector: 'app-update-password-user',
  templateUrl: './update-password-user.component.html',
  styleUrls: ['./update-password-user.component.css']
})
export class UpdatePasswordUserComponent implements OnInit {
  public formModel: FormModel = {};
  recaptcha : any[];
  form: FormGroup;
  model = {
    email: '',
    code: ''
  };
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  updatePassword: Boolean;


  constructor(private userService: UserService, private router: Router, fb: FormBuilder, private route: ActivatedRoute) {
    this.form = fb.group({
      // define your control in you form
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
    });
  }
  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  ngOnDestroy(){
  
  }
  ngOnInit() {
    
    const id = this.route.snapshot.paramMap.get('id');
    this.controlIdUser(id);
  }

  controlIdUser(id) {
    this.userService.controlIdUser(id).subscribe(
      res => {
        if (res['authorization'] === true) {
          this.updatePassword = true;
        } else {
          this.updatePassword = false;
        }
      },
      err => {
        this.updatePassword = false;
      });
  }

  onSubmit(form: NgForm) {

    if (this.form.status === 'VALID') {
      const id = this.route.snapshot.paramMap.get('id'); 
      var formValue = this.form.value;
      this.userService.updatePasswordIdCheck(formValue,id).subscribe(
        res => {
          if (res['status'] === true) {
            this.router.navigateByUrl('/login')
          } else {
            //
          }
        },
        err => {
          //this.updatePassword = false;
        });
    } else {

    }
  }


}
