import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import { UserService } from '../../shared/user.service';


export interface FormModel {
  captcha?: string;
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  public formModel: FormModel = {};
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  showSucessMessage: boolean;
  serverErrorMessages: string;
  recaptcha : any[];

  constructor(public userService: UserService, private router: Router) { }
  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  ngOnDestroy(){
    
  }
  ngOnInit() {
    
    if (this.userService.isLoggedIn())
    this.router.navigateByUrl('/dashboard');
  }

  onSubmit(form: NgForm) {

    form.value.active = false;
    form.value.role = [];
    form.value.role.push({
        name : 'adminOrganisation',
        description: 'All right'
    });

    form.value.organisation = null;

    this.userService.postUser(form.value).subscribe(
      res => {
        this.showSucessMessage = true;
        setTimeout(() => this.showSucessMessage = false, 4000);
        this.resetForm(form);
        this.router.navigate(['/login']);
      },
      err => {
        if (err.status === 422) {
          this.serverErrorMessages = err.error.join('<br/>');
        } else {
          this.serverErrorMessages = 'Something went wrong.Please contact admin.';
        }
      }
    );
  }

  resetForm(form: NgForm) {
    this.userService.selectedUser = {
      fullName: '',
      email: '',
      password: ''
    };
    form.resetForm();
    this.serverErrorMessages = '';
  }

}
