import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Organisation } from './../../../shared/organisation.model';
import { OrganisationService } from './../../../shared/organisation.service';
import { User } from 'src/app/shared/user.model';

@Component({
  selector: 'app-read-organisation-admin',
  templateUrl: './read-organisation-admin.component.html',
  styleUrls: ['./read-organisation-admin.component.css']
})
export class ReadOrganisationAdminComponent implements OnInit {

  organisation = {
    _id: '',
    organisationName: '',
    description: '',
    address: {
      city: '',
      postal_code: '',
      line1: '',
      state: '',
            },
    president: {
      dobindividual: '',
      first_name: '',
      last_name: '',
      phone: '',
    },
    iban: '',
    accountStripe: {
      email: ''
    },
    id_connected_account : Boolean
  };
  activeAccountByAdmin;
  stripeActiveAccountByAdmin;

  linkDocument01 = '';
  linkDocument02 = '';

  constructor(private route: ActivatedRoute, private organisationService: OrganisationService) { }

  ngOnInit() {
        /**
         * get organisation by id
         */
        this.organisationService.getOrganisationById(this.route.snapshot.paramMap.get('id')).subscribe(
          res => {
            this.getUserByOrganisation(res['organisation']._id);
            // this.getDocument('r5d39e77007ca5b52b05b2710-.png');
            this.getUserByOrganisationId(res['organisation']._id);
            this.organisation = res['organisation'];
            this.organisation.president.dobindividual = this.formatDate(new Date(res['organisation'].president.dobindividual));
          },
          err => {
            console.log(err);
          }
        );
  }

  getDocument(filename) {
    this.organisationService.getDocumentOrganisation(filename).subscribe(
      res => {
        // console.log(res);
        // this.createImageFromBlob(res['image'].data);
        // console.log(res);
        // let file = new Blob([res['image'].data], { type: 'image/jpeg' });
        // var fileURL = URL.createObjectURL(file);
        // window.open(fileURL);
      },
      err => {
        console.log(err);
      }
    );
  }

getUserByOrganisation(id) {
  this.organisationService.getUsersByOrganisationId(id).subscribe(
    res => {
      this.linkDocument01 = 'http://localhost:3002/api/organisation/images/r' + res['user']._id + '-.png';
      this.linkDocument02 = 'http://localhost:3002/api/organisation/images/v' + res['user']._id + '-.png';
      this.stripeActiveAccountByAdmin = res['user'].stripeActiveAccountByAdmin;
      console.log(this.stripeActiveAccountByAdmin);
    },
    err => {
    console.log(err);
    }
  );
}

  formatDate(date) {
    const days = date.getDate();
    const month = date.getMonth() + 1; // Be careful! January is 0 not 1
    const year = date.getFullYear();
    if (month < 10) {
      return '0' + days + '-0' + month + '-' + year;
    } else {
      return days + '-' + month + '-' + year;
    }
}

getUserByOrganisationId(id) {
  this.organisationService.getUsersByOrganisationId(id).subscribe(
    res => {
        this.activeAccountByAdmin = res['user'].activeAccountByAdmin;
        console.log(res['user']);
        this.stripeActiveAccountByAdmin = res['user'].stripeActiveAccountByAdmin;
    },
    err => {
        // this.user = null;
    }
  );
}

activeAccountUserOrganisation(id) {
  this.organisationService.activeAccountUserOrganisation(id).subscribe(
    res => {
      this.activeAccountByAdmin = 'active';
    },
    err => {
        console.log(err);
    }
  );
}

disableAccountUserOrganisation(id) {
  this.organisationService.disableAccountUserOrganisation(id).subscribe(
    res => {
        // this.user = res['user'];
        this.activeAccountByAdmin = 'disable';
        // this.activeAccountByAdmin = res['user'].activeAccountByAdmin;
        // console.log(res);
    },
    err => {
        console.log(err);
    }
  );
}

stripeActiveAccountUserOrganisation(id) {
  this.organisationService.stripeActiveAccountUserOrganisation(id).subscribe(
    res => {
      this.stripeActiveAccountByAdmin = 'active';
    },
    err => {
        console.log(err);
    }
  );
}

stripeDisableAccountUserOrganisation(id) {
  this.organisationService.stripeDisableAccountUserOrganisation(id).subscribe(
    res => {
        this.stripeActiveAccountByAdmin = 'disable';
    },
    err => {
        console.log(err);
    }
  );
}





imageToShow: any;

createImageFromBlob(image: Blob) {
   let reader = new FileReader();

   reader.addEventListener("load", () => {
      this.imageToShow = reader.result;

   }, false);

   if (image) {
      reader.readAsDataURL(image);
      console.log(this.imageToShow);
   }
}

// getImageFromService() {
//   this.isImageLoading = true;
//   this.imageService.getImage(yourImageUrl).subscribe(data => {
//     this.createImageFromBlob(data);
//     this.isImageLoading = false;
//   }, error => {
//     this.isImageLoading = false;
//     console.log(error);
//   });
// }




}
