import { Organisation } from './../../../shared/organisation.model';
import { Kiosk } from './../../../shared/kiosk.model';
import { Component, OnInit } from '@angular/core';
import { OrganisationService } from './../../../shared/organisation.service';
import { PagerService } from '../../../shared/pager.service';
import { all } from 'q';
import { Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-list-organisation-admin',
  templateUrl: './list-organisation-admin.component.html',
  styleUrls: ['./list-organisation-admin.component.css']
})
export class ListOrganisationAdminComponent implements OnInit {

   organisationsArray = [];
   // array of all items to be paged
   private allItems: any[];
   // pager object
   pager: any = {};
   // paged items
   pagedItems: any[];
   listOrganisations: Boolean = false;
   organisation: Organisation;

  constructor(private organisationService: OrganisationService, private pagerService: PagerService, private router: Router) { }

  ngOnInit() {
    this.list();
  }

  list() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {
        // this.organisationsArray = res['organisation'];
        // this.allItems = res['organisation'];
        this.createObjectOrganisationKiosks(res);
        if ( this.allItems.length !== 0 ) {
          this.listOrganisations = true;
        }
        // initialize to page 1
        this.setPage(1);
      },
      err => {

      }
    );
  }

  createObjectOrganisationKiosks(response) {
    const organisationsArray = [];
    this.organisationsArray = response['organisation'];
    this.allItems = response['organisation'];
    if (response['kiosks'] !== null) {
      /**
       * Get kiosk by organisation
       */
      response['organisation'].forEach(function(organisation) {
        organisation.Kiosk = response['kiosks'].filter(x => x.idOrganisation = organisation._id);
        organisationsArray.push(organisation);
      });
      this.organisationsArray = organisationsArray;
      this.allItems = organisationsArray;
    }
  }

  showKiosks(organisation) {
    this.router.navigateByUrl('/dashboard/admin/organisation/kiosks/' + organisation._id);
  }

delete() {

}

disableAccount() {
    this.organisationService.disableAccountUserOrganisation(this.organisation._id).subscribe(
      res => {
        this.organisation.user.activeAccountByAdmin = 'disable';

      },
      err => {
          console.log(err);
      }

    );
}

comfirmationDisable(organisation) {

  this.organisation = organisation;

}

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }



  searchOrganisation(searchValue: string) {
    var organisationsArray =  this.organisationsArray.filter(x => x.organisationName.toUpperCase().includes(searchValue.toUpperCase()) ||
                                                             x.president.first_name.toUpperCase().includes(searchValue.toUpperCase()) ||
                                                             x.president.last_name.toUpperCase().includes(searchValue.toUpperCase()) ||
                                                             x.address.city.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = organisationsArray;
    this.setPage(1);

  }

}

