import {AbstractControl} from '@angular/forms';

export class PasswordValidation {

     static MatchPassword(AC: AbstractControl) {



     let password = AC.get('password').value; // to get value in input tag
     let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag

     //var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
     //var strongRegex = new RegExp("/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/");
     //var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
       var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
   //   console.log(mediumRegex.test(password));
   //   if (mediumRegex.test(password)) {
   //        console.log('no strong pass');
   //   }

     if (mediumRegex.test(password) === false ) {
        AC.get('confirmPassword').setErrors( { structurePass: true } );        
     } else if (password !== confirmPassword) {
        AC.get('confirmPassword').setErrors( { MatchPassword : true } );
     } else {        
        return null;
     }




      // if (password !== confirmPassword) {
      //   // AC.get('confirmPassword').setErrors( {MatchPassword: true, structurePass: false} );
      //   AC.get('confirmPassword').setErrors( {MatchPassword: true} );
      // }
      // else if (mediumRegex.test(password) === false ) {
      //   AC.get('confirmPassword').setErrors( {structurePass: true} );
      // }
      // else {
      //   console.log('true');
      //   return null;
      // }

      // if (password !== confirmPassword || strongRegex.test(password) === false) {
      //       console.log('false');
      //       AC.get('confirmPassword').setErrors( {MatchPassword: true, structurePass: true} );

      //   } else {
      //       console.log('true');
      //       return null
      //   }
    }
}
