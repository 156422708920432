import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { OrganisationService } from '../../shared/organisation.service';
import { ShareDataService } from '../../shared/shareData.service';
import { UserService } from '../../shared/user.service';
var _ = require('lodash');
interface Template {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-dashboard-select-organisation',
  templateUrl: './dashboard-select-organisation.component.html',
  styleUrls: ['./dashboard-select-organisation.component.css',
  './bootstrap.css',
  './bs-switches.css']
})


export class DashboardSelectOrganisationComponent implements OnInit {

  constructor(
    private userService: UserService,
    private organisationService: OrganisationService,
    private shareDataService:ShareDataService

  ) { }
  listArrayUsers = [];
  listArrayAllUsers = [];
  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
    user : {}
  }
  tListTypeOrga: Template[] = [];
  errorMsg : boolean = false;

  @Output() MessageEvent = new EventEmitter<any>();

  ngOnInit(): void {
    this.shareDataService.currentMessage.subscribe(selectOrga => this.selectedOrga = selectOrga);
    this.getOrganizationList();
    this.listUsers();


  }

  formatListOrganisation(resData){
    var that = this;
    that.tListTypeOrga = [];
    that.tListTypeOrga.push({value: '',viewValue : 'Selectionnez une orga' });
    if(resData !== undefined && resData !== null){
      if(Array.isArray(resData)){
        resData.forEach(elem => {
          var elemData = {
            value  : elem._id,
            viewValue : elem.organisationName
          };

          that.tListTypeOrga.push(elemData);

        });


      }
    }
  }


  getOrganizationList() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {
        console.log(res);

        this.formatListOrganisation(res['organisation'])
      },
      err => {
       
        console.log(err);
      }

    );
  }

  filterByCurrentOrganisation (){
    var currentOrganisation = this.selectedOrga.idOrganisation;

    this.listArrayUsers = this.listArrayAllUsers.filter(user => user.idOrganisation === currentOrganisation);

  }


  listUsers() {
    this.errorMsg = false;
    this.userService.getUserListAd().subscribe(
      res => {
        this.listArrayAllUsers = res['List'];  
        this.filterByCurrentOrganisation();     
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  GetValueTypeOrga(event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.selectedOrga.idOrganisation = selectedOptions[selectedIndex].getAttribute("ng-reflect-ng-value");
    this.selectedOrga.organisationName = selectElementText;
    this.MessageEvent.emit(this.selectedOrga);

    this.shareDataService.addToCart(this.selectedOrga);
    this.shareDataService.changeMessage(this.selectedOrga);

    this.filterByCurrentOrganisation();

   
  }


  GetValueUsers(event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    this.selectedOrga.user = selectedOptions[selectedIndex].getAttribute("ng-reflect-ng-value");
    
    this.MessageEvent.emit(this.selectedOrga);

    this.shareDataService.addToCart(this.selectedOrga);
    this.shareDataService.changeMessage(this.selectedOrga);

  

   
  }
}
