import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripePayoutService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }

  getStripePayoutByOrganization(id) {
    return this.http.get(environment.apiBaseUrl + '/stripe/payout/transaction/organization/' + id);
  }

}
