
import { Component, OnInit } from '@angular/core';
import { Transaction } from './../../../shared/transaction.model';
import { TransactionService } from './../../../shared/transaction.service';
import { StripeTransactionService } from './../../../shared/stripeTransaction.service';
import { WhitelistService } from './../../../shared/whitelist.service';
import { Whitelist } from './../../../shared/whitelist.model';
import { UserService } from './../../../shared/user.service';
import { PagerService } from './../../../shared/pager.service';
import { OrganisationService } from './../../../shared/organisation.service';
import { Organisation } from './../../../shared/organisation.model';

import { KpiService } from './../../../shared/kpi.service';
import { Dashboarddata } from './../../../shared/dashaboardata';
import { ShareDataService } from './../../../shared/shareData.service';
import { formatter } from './../../../shared/formatter';
import { Router } from '@angular/router';
import { CountUp } from "countup.js";
import { typeWithParameters } from '@angular/compiler/src/render3/util';

@Component({
  selector: 'app-manage-userlist',
  templateUrl: './manage-userlist.component.html',
  styleUrls: ['./manage-userlist.component.css']
})
export class ManageUserlistComponent implements OnInit {

  formartter: formatter;
  dashboarddata: Dashboarddata;
  modelDashboard: any;
  idOrganization: String;
  transactionArray = [];
  whitelistArray = [];
  listArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  currentWhitelist: Whitelist;
  errorMsg;
  validEmail;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  email: String = '';

  isEmailSended;
  isEmailNotSend;
  isEmailSending: Boolean = false;

  organization: Organisation;
  whitelist: Whitelist[];


  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }

  constructor(private transactionService: TransactionService,
    private stripeTransactionService: StripeTransactionService,
    private userService: UserService,
    private pagerService: PagerService,
    private organisationService: OrganisationService,
    private kpiService: KpiService,
    private shareDataService: ShareDataService,
    private router: Router,
    private whitelistService: WhitelistService) {
    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService, shareDataService);
    this.modelDashboard = this.dashboarddata.modelDashboard;
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    // this.loadExternalScript('assets/js/jquery.calendario.js');
    // this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    // this.loadExternalScript('assets/js/functions.js');
  }

  redirectSelectOrga() {
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }

  getIdOrganisation() {
    this.selectedOrga = this.shareDataService.getItems();
    if (this.selectedOrga !== undefined && this.selectedOrga !== null) {
      if (this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null) {
        if (this.selectedOrga.idOrganisation === "") {
          //this.redirectSelectOrga();
        } else {
          return this.selectedOrga.idOrganisation;
        }
      }
    }
    return "";
  }

  ngOnInit() {
    var that = this;
    this.idOrganization = this.getIdOrganisation();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.list();
        setTimeout(function () { that.callback(); }, Dashboarddata.delay);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  deleteWhitelist(id) {
    this.whitelistService.removeWhitelist(id).subscribe(
      res => {
        this.list();
      },
      err => {
        this.errorMsg = true;
        this.list();
      }
    );
  }

  list() {
    this.userService.getUserListAd().subscribe(
      res => {
        this.listArray = res['List'];
        this.allItems = res['List'];
        this.listArray.reverse();
        if (this.allItems.length !== 0) {
          this.listTransaction = true;
        } else {
          this.listTransaction = false;
        }
        this.setPage(1);
      },
      err => {
        this.errorMsg = true;
      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchCampaign(searchValue: string) {
    const whitelistArray = this.whitelistArray.filter(x => x.idStripe.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = whitelistArray;
    this.setPage(1);
  }

  selectCurrentCampaign(whitelist) {
    this.currentWhitelist = whitelist;
  }

  callback() {
    if (this.userService.isLoggedIn()) {
      this.dashboarddata.formatDataDashboardAll(this);
    } else {

    }
  }

}

