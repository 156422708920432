import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.css']
})
export class KioskComponent implements OnInit {

  // isListKiosk = true;
  // currentKiosk = {};
  // listKiosk = [
  //   {
  //   id: '1029384765PAZJDU',
  //   type: 'Borne fixe',
  //   location: 'Salle des hommes',
  //   dateCheck: '08/06/2019',
  //   dateInstallation: '08/05/2019',
  //   supervisor: 'Jaouad Essika',
  //   comment: 'Borne mise à disposition des donateurs du centre culturel musulman pour la récolte de la zakat et autre aumone des fidèles'
  //   },
  //   {
  //   id: '564783902341UAZU',
  //   type: 'Borne fixe',
  //   location: 'Salle des hommes',
  //   dateCheck: '08/06/2019',
  //   dateInstallation: '08/05/2019',
  //   supervisor: 'Jaouad Essika',
  //   comment: 'Borne mise à disposition des donateurs du centre culturel musulman pour la récolte de la zakat et autre aumone des fidèles'
  // },
  //   {
  //   id: '562863469021UFRD',
  //   type: 'Borne fixe',
  //   location: 'Salle des femmes',
  //   dateCheck: '08/06/2019',
  //   dateInstallation: '08/05/2019',
  //   supervisor: 'Jaouad Essika',
  //   comment: 'Borne mise à disposition des donateurs du centre culturel musulman pour la récolte de la zakat et autre aumone des fidèles'
  //   },
  //   {
  //   id: '56478393Z021RTUU',
  //   type: 'Borne fixe',
  //   location: 'Salle des cours',
  //   dateCheck: '08/06/2019',
  //   dateInstallation: '08/05/2019',
  //   supervisor: 'Jaouad Essika',
  //   comment: 'Borne mise à disposition des donateurs du centre culturel musulman pour la récolte de la zakat et autre aumone des fidèles'
  //   }
  // ];

  constructor() { }

  ngOnInit() {
  }

  // detailKiosk( kiosk ) {
  //   this.currentKiosk = kiosk;
  //   this.isListKiosk = false;
  // }

  // showlistKiosk() {
  //   this.isListKiosk = true;
  // }



}
