import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donator',
  templateUrl: './donator.component.html',
  styleUrls: ['./donator.component.css']
})
export class DonatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
