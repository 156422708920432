// import { Kiosk } from './kiosk.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KioskServiceBis {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient) { }


  kioskByIdAndOrganisation(idO, idK) {
    return this.http.get(environment.apiBaseUrl + '/kiosk/transaction/' + idO + '/' + idK);
  }


  getKiosksByOrganisation(id) {
    return this.http.get(environment.apiBaseUrl + '/kiosks/organisation/transactions/' + id);
  }

}
