import { OrganisationService } from './../../shared/organisation.service';
import { Organisation } from './../../shared/organisation.model';
import { UserService } from '../../shared/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareDataService } from '../../shared/shareData.service';
@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  userDetails = {
    organisation: Organisation
  };
  idOrganization: String;
  organisation: Organisation;
  status: Boolean;
  organisationName: String;
  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }
  constructor(private organisationService: OrganisationService, private userService: UserService,private shareDataService:ShareDataService,private router :Router) { }
  redirectSelectOrga(){
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }
    getIdOrganisation(){
       
      this.selectedOrga = this.shareDataService.getItems();
      if (this.selectedOrga !== undefined && this.selectedOrga !== null){
        if(this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null ){
          if(this.selectedOrga.idOrganisation === ""){
            this.redirectSelectOrga();
          }else{
            return this.selectedOrga.idOrganisation;
          }
         
        }
  
      }
  
      return "";
    }
 ngOnInit() {
this.idOrganization = this.getIdOrganisation();
  this.userService.getUserProfileAdm().subscribe(
    res => {
      this.userDetails = res['user'];
      this.getOrganisationByUserId(this.idOrganization);
      // this.userDetails.organisation = res['user'].organisation;
      // console.log(this.userDetails);
    },
    err => {
      console.log(err);
    }
  );

}

getOrganisationByUserId(id) {
  this.organisationService.getOrganisationById(id).subscribe(
    res => {
        console.log(res);
        this.organisation = res['organisation'];
        this.status = true;
    },
    err => {
        this.status = false;
        this.organisationName = err.error.message;
        console.log(err.error.message);
    }
  );
}


}
