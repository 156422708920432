
import { Organisation } from './../../shared/organisation.model';
import { Component, OnInit } from '@angular/core';
import { AngularIbanModule } from 'angular-iban';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { OrganisationService } from './../../shared/organisation.service';
import { UserService } from './../../shared/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-external-account-stripe',
  templateUrl: './external-account-stripe.component.html',
  styleUrls: ['./external-account-stripe.component.css']
})
export class ExternalAccountStripeComponent implements OnInit {

  iban: AngularIbanModule;
  isSameIban: Boolean;
  testIban: '';
  accountDetails = {
    confirmRib: '',
    rib: '',
  };
  organization: Organisation;
  errorMsg = false;
  validMsg = false;
  idConnectedAccount = '';
  presidentFullName = '';
  accountId = '';
  ibanEmpty;
  constructor(private angularIbanModule: AngularIbanModule, private organisationService: OrganisationService,
              private userService: UserService, private router: Router) { }

  ngOnInit() {
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.presidentFullName = res['user'].fullName;
        this.getOrganisationByUserId(res['user'].idOrganisation);
      }
    );
  }

   // If the both IBAN is same
   isSameIbanFunc(confirmRib) {

    if (confirmRib.model === this.testIban ) {
      this.isSameIban = true;
    } else {
      this.isSameIban = false;
    }
  }

  isIbanEmpty() {
   if (!this.testIban) {
      this.ibanEmpty = true;
      setTimeout( () => {
      this.ibanEmpty = false;
      }, 2000);
   }
  }
  onSubmit(form: NgForm) {

    if (this.testIban !== form.value.confirmRib) {
      return;
    }



    const infoOrganization = {
      presidentFullName: this.presidentFullName,
      iban : this.testIban,
      id_connected_account: this.idConnectedAccount,
      accountId: this.accountId
    };

    this.organisationService.createExternalAccountStripe(infoOrganization).subscribe(
      res => {
          infoOrganization['activeAccount'] = true;
          infoOrganization['bankInfo'] = res['bank_account'];
          this.organisationService.updateExternalAccountStripe(infoOrganization).subscribe(
            _res => {
              this.validMsg = true;
              setTimeout( () => {
              this.router.navigateByUrl('/dashboard');
              }, 3000);
            },
            _err => {
              this.errorMsg = true;
              setTimeout( () => {
                this.errorMsg = false;
                }, 3000);
            }
          );
      },
      err => {
        this.errorMsg = true;
        setTimeout( () => {
          this.errorMsg = false;
          }, 3000);
      }
    );
  }

  getOrganisationByUserId(id) {
    this.organisationService.getOrganisationById(id).subscribe(
      res => {
        this.idConnectedAccount = res['organisation'].accountStripe.id;
        this.accountId = res['organisation'].id_connected_account;
        if (res['organisation'].externalAccountStripe === true) {
          this.router.navigateByUrl('/dashboard');
        }
      }
    );
  }

}
