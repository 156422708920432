import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserService } from "../../shared/user.service";
@Injectable({
  providedIn: 'root'
})
export class AdminStripeTransactionService {

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };

  constructor(private http: HttpClient,private userService : UserService) { }

  sumTransactionStripe() {
    return this.http.get(environment.apiBaseUrl + '/admin/stripe/transaction/sum');
  }

  sumTransactionStripePerDay() {
    return this.http.post(environment.apiBaseUrl + '/admin/stripe/transaction/organization/day/sum/', { date: new Date()});
  }

  sumTransactionStripePerMonth() {
    return this.http.post(environment.apiBaseUrl + '/admin/stripe/transaction/organization/month/sum/', { date: new Date()});
  }

  downloadStripe(){
    let headers = new HttpHeaders({
      "Authorization": "Bearer " + this.userService.getToken(), // Auth header
      //No other headers needed
  });
    return this.http.get(environment.apiBaseUrl + '/getDataStripeTransactionFile',{ headers, responseType: "blob" });
  }

  generatedownloadStripe(){
    let headers = new HttpHeaders({
      "Authorization": "Bearer " + this.userService.getToken(), // Auth header
      //No other headers needed
  });
    return this.http.get(environment.apiBaseUrl + '/getDataStripeTransaction',{ headers, responseType: "blob" });
  }

}
