import { Component, OnInit } from '@angular/core';
import { PagerService } from '../../../shared/pager.service';

@Component({
  selector: 'app-list-donation-campaign-admin',
  templateUrl: './list-donation-campaign-admin.component.html',
  styleUrls: ['./list-donation-campaign-admin.component.css']
})
export class ListDonationCampaignAdminComponent implements OnInit {

  // array of all items to be paged
   private allItems: any[];
   // pager object
   pager: any = {};
   // paged items
   pagedItems: any[];
  constructor(private pagerService: PagerService) { }

  ngOnInit() {

    console.log('je suis là');

  }

}
