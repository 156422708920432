import { Organisation } from '../../../shared/organisation.model';
import { ActivatedRoute } from '@angular/router';
import { Kiosk } from '../../../shared/kiosk.model';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { OrganisationService } from '../../../shared/organisation.service';
import { KioskService } from '../../../shared/kiosk.service';
import { PagerService } from '../../../shared/pager.service';
import { WhitelistService } from './../../../shared/whitelist.service';
import { Whitelist } from './../../../shared/whitelist.model';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// the second parameter 'fr' is optional
//registerLocaleData(localeFr, 'fr');
interface Template3 {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-manage-usercreate',
  templateUrl: './manage-usercreate.component.html',
  styleUrls: ['./manage-usercreate.component.css']
})
export class ManageUsercreateComponent implements OnInit {
  

  tListTypeOrga: Template3[] = [];
  idWhitelist: String;
  modeEdit: Boolean = false;
  currentWhitelist: Whitelist;
  whitelistData: Whitelist;

  islistKiosk: Boolean = true;
  isOrganisationSelected: Boolean = false;
  form: FormGroup;
  fb: FormBuilder;
  actionSubmit: String = 'CREATE';
  organisation = [];
  organisationCurrent: Organisation;
  isSuccessMessage: Boolean = false;
  kiosksArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

 

  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }



  constructor(private organisationService: OrganisationService, private kioskService: KioskService, fb: FormBuilder,
    private pagerService: PagerService,
    private whitelistService: WhitelistService,
    private route: ActivatedRoute) {
    this.currentWhitelist = new Whitelist();

    this.selectedOrga.idOrganisation = '';
    this.form = fb.group({
      id: [''],
      active: ['', Validators.required],
      activeDate: ['', Validators.required],
      MerchantIdentifier: ['', Validators.required],
      MerchantSecretKey: ['', Validators.required],
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required],
      idOrganization: ['', Validators.required],
      mac: ['', Validators.required],
      uuid: ['', Validators.required],
      selectedOrga:  ['', Validators.required]
    });
  }



  setForm(data) {

    this.form.controls['id'].setValue(data.id);
    this.form.controls['active'].setValue(data.active);
    this.form.controls['activeDate'].setValue(data.activeDate);
    this.form.controls['MerchantIdentifier'].setValue(data.MerchantIdentifier);
    this.form.controls['MerchantSecretKey'].setValue(data.MerchantSecretKey);
    this.form.controls['dateFrom'].setValue(data.dateFrom);
   
    this.form.controls['dateTo'].setValue(data.dateTo);
    this.form.controls['idOrganization'].setValue(data.idOrganization);
    this.form.controls['selectedOrga'].setValue(data.idOrganization);
    
    this.form.controls['mac'].setValue(data.mac);
    this.form.controls['uuid'].setValue(data.uuid);
    this.selectedOrga.idOrganisation = data.idOrganization;



  }
  ngOnInit() {
    this.whitelistData = new Whitelist();
    //this.selectedOrga.idOrganisation = "5f7f2d4e4ea74415cc30eee5";
    this.listOrganisations();
    this.getOrganizationList();
    this.getData();

  }

  getData() {

    var path = this.route.snapshot.routeConfig.path;
    if (path === "update/:id") {
      this.modeEdit = true;
      this.list();
    }


  }
  getFormSubmit() {
    this.currentWhitelist.id = this.form.value.id;
    this.currentWhitelist.active = this.form.value.active;
    this.currentWhitelist.activeDate = this.form.value.activeDate;
    this.currentWhitelist.MerchantIdentifier = this.form.value.MerchantIdentifier;
    this.currentWhitelist.MerchantSecretKey = this.form.value.MerchantSecretKey;
    this.currentWhitelist.dateFrom = this.form.value.dateFrom;
    this.currentWhitelist.dateTo = this.form.value.dateTo;
    //this.currentWhitelist.idOrganization = this.form.value.idOrganization;
    this.currentWhitelist.idOrganization = this.form.value.selectedOrga;
    
    this.currentWhitelist.mac = this.form.value.mac;
    this.currentWhitelist.uuid = this.form.value.uuid;


    return this.currentWhitelist;

  }
  onSubmit() {
    var WhitelistModel = this.getFormSubmit();
    this.whitelistService.addWhitelist(WhitelistModel).subscribe(
      res => {
        this.form.reset();
        this.islistKiosk = true;
        this.isSuccessMessage = true;
        setTimeout(() => this.isSuccessMessage = false, 4000);
        this.list();

      },
      err => {

      }
    );

  }

  formatListOrganisation(resData){
    var that = this;
    that.tListTypeOrga = [];
    that.tListTypeOrga.push({value: '',viewValue : 'Selectionnez une orga' });
    if(resData !== undefined && resData !== null){
      if(Array.isArray(resData)){
        resData.forEach(elem => {
          var elemData = {
            value  : elem._id,
            viewValue :elem._id + '-' +  elem.organisationName
          };

          that.tListTypeOrga.push(elemData);

        });


      }
    }
  }

  getOrganizationList() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {
        console.log(res);

        this.formatListOrganisation(res['organisation'])
      },
      err => {
       
        console.log(err);
      }

    );
  }

  create() {
    this.islistKiosk = false;
    this.actionSubmit = 'CREATE';
  }

  list() {
    this.islistKiosk = true;
    this.idWhitelist = this.route.snapshot.paramMap.get('id');
    this.whitelistService.getItem(this.idWhitelist).subscribe(
      res => {
        this.whitelistData = res['whitelist'];
        this.currentWhitelist = res['whitelist'];

        //this.selectedOrga.idOrganisation = this.whitelistData.idOrganization;
        this.setForm(this.whitelistData);
      },
      err => {

      }
    );
  }

  GetValueTypeOrga(event) {
    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.selectedOrga.idOrganisation = selectedOptions[selectedIndex].getAttribute("ng-reflect-ng-value");
    this.selectedOrga.organisationName = selectElementText;


   
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  update(kiosk) {
    console.log(this.organisationCurrent);
    this.islistKiosk = false;
    this.actionSubmit = 'UPDATE';
    if (kiosk.organisationName !== 'Aucune Organisation') {
      this.isOrganisationSelected = true;
      // Get organisation
      this.organisationCurrent = kiosk.organisation;
    }
    this.form.value.organisationSelected = this.organisationCurrent;

    this.form.controls['id'].setValue(kiosk._id);
    this.form.controls['type'].setValue(kiosk.type);
    this.form.controls['supervisor'].setValue(kiosk.supervisor);
    this.form.controls['line01'].setValue(kiosk.address.line01);
    this.form.controls['city'].setValue(kiosk.address.city);
    this.form.controls['postaleCode'].setValue(kiosk.address.postaleCode);
    this.form.controls['state'].setValue(kiosk.address.state);
    this.form.controls['location'].setValue(kiosk.location);
    this.form.controls['description'].setValue(kiosk.description);

  }

  delete() {
    console.log("je suis dans le delete");
  }

  listOrganisations() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {

        this.organisation = res['organisation'];

      },
      err => {

      }
    );
  }

  addOrganisation(orgnaisation) {

    this.isOrganisationSelected = true;
    this.organisationCurrent = orgnaisation;
    // this.organisationCurrent.email = orgnaisation.accountStripe.email;
  }

  deleteSelectedOrganisation() {
    this.organisationCurrent.organisationName = '';
    this.organisationCurrent.accountStripe.email = '';
    this.isOrganisationSelected = false;
  }

  updateSelectedOrganisation() {
  }


  searchKiosk(searchValue: string) {
    var kiosksArray = this.kiosksArray.filter(x => x.id_kiosk.toUpperCase().includes(searchValue.toUpperCase()) ||
      x.organisationName.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = kiosksArray;
    this.setPage(1);

  }

}

