import { Component, OnInit } from '@angular/core';
import { UserService } from './../../shared/user.service';
import { Router } from '@angular/router';
import { User } from '../../shared/user.model';
// Service
 import { DynamicScriptLoaderService } from '../../shared/DynamicScriptLoaderService';

@Component({
  selector: 'app-dashboard-menu-admin',
  templateUrl: './dashboard-menu-admin.component.html',
  styleUrls: ['./dashboard-menu-admin.component.css']
})
export class DashboardMenuAdminComponent implements OnInit {

  userDetails = {
    fullName: ''
  };

  constructor(private userService: UserService, private router: Router, private dynamicScriptLoaderService: DynamicScriptLoaderService) { }
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit() {
    // this.loadScripts();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.userDetails = res['user'];
        this.userDetails.fullName = res['user'].fullName;
      },
      err => {
        console.log(err);
      }
    );

  //   (async () => {
  //     // Do something before delay
  //     console.log('before delay')

  //     await this.delay(5000);

  //     // Do something after
  //     console.log('after delay');
  //       // You can load multiple scripts by just providing the key as argument into load method of the service
  //   this.dynamicScriptLoaderService.load('functions-js').then(data => {
  //     // Script Loaded Successfully
  //   }).catch(error => console.log(error));
  // })();


  }
  ngAfterViewInit() {
    // this.loadExternalScript('../../assets/js/jquery.js');
    // this.loadExternalScript('../../assets/js/jquery.calendario.js');
    // this.loadExternalScript('../../assets/demos/nonprofit/js/events.js');
    //  this.loadExternalScript('../../assets/js/functions.js');+
    // this.loadExternalScript('../../assets/js/plugins.js');
    // this.loadExternalScript('../../assets/js/functions.js');
  }
//   delay(ms: number) {
//     return new Promise( resolve => setTimeout(resolve, ms) );
// }

  // private loadScripts() {
  //   // You can load multiple scripts by just providing the key as argument into load method of the service
  //   this.dynamicScriptLoader.load('functions-js', 'header-css').then(data => {
  //     // Script Loaded Successfully
  //   }).catch(error => console.log(error));
  // }

  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }


}
