import { Component, OnInit } from '@angular/core';
import { Transaction } from '../../../shared/transaction.model';
import { StripeTransaction } from '../../../shared/stripeTransaction.model';
import { TransactionService } from '../../../shared/transaction.service';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { ActivatedRoute ,Router} from '@angular/router';
import { Campaign } from './../../../shared/campaign.model';
import { ShareDataService } from './../../../shared/shareData.service';

@Component({
  selector: 'app-read-transaction',
  templateUrl: './read-transaction.component.html',
  styleUrls: ['./read-transaction.component.css']
})
export class ReadTransactionComponent implements OnInit {

  // transaction: Transaction;
  transaction: StripeTransaction;
  campaign: Campaign;
  errorMsg: Boolean;
  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }

  constructor(private transactionService: TransactionService, private route: ActivatedRoute,private shareDataService: ShareDataService,
              private stripeTransactionService: StripeTransactionService,private router:Router) {
    // this.transaction = new Transaction();
    this.transaction = new StripeTransaction();
    this.campaign = new Campaign();
   }
   redirectSelectOrga(){
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }
    getIdOrganisation(){
       
      this.selectedOrga = this.shareDataService.getItems();
      if (this.selectedOrga !== undefined && this.selectedOrga !== null){
        if(this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null ){
          if(this.selectedOrga.idOrganisation === ""){
            this.redirectSelectOrga();
          }else{
            return this.selectedOrga.idOrganisation;
          }
         
        }
  
      }
  
      return "";
    }
  ngOnInit() {
    
    this.stripeTransactionService.getTransactionStripeById(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
        this.transaction = res['transaction'][0];
        this.campaign = res['transaction'][0].transacCampaing[0];
      },
      err => {
        this.errorMsg = true;
      });

    // this.transactionService.getTransactionById(this.route.snapshot.paramMap.get('id')).subscribe(
    //   res => {
    //     this.transaction = res['transaction'][0];
    //     this.campaign = res['transaction'][0].transacCampaing[0];
    //   },
    //   err => {
    //     this.errorMsg = true;
    //   });
  }

}
