import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KioskService } from './../../../shared/kiosk.service';
import { Kiosk } from './../../../shared/kiosk.model';
import { KioskServiceBis } from '../../../shared/kiosk.serviceBis';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { UserService } from '../../../shared/user.service';
import { TransactionService } from '../../../shared/transaction.service';
import { OrganisationService } from '../../../shared/organisation.service';
import  { formatter } from '../../../shared/formatter';
import {Dashboarddata} from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';

import { SimpleTimer } from 'ng2-simple-timer';
import { ShareDataService } from '../../../shared/shareData.service';
interface Template {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-read-kiosk',
  templateUrl: './read-kiosk.component.html',
  styleUrls: ['./read-kiosk.component.css']
})


export class ReadKioskComponent implements OnInit {

  formartter : formatter ; 
  dashboarddata : Dashboarddata;
  timerId: string;
  modelDashboard : any;

  idOrganization: String;
  idKiosk: String;

  kiosk: Kiosk;
  sumTransaction: any = 0;
  totalTransaction: any = 0;
  averageTransaction: any = 0;
  selectedTemplate: String;
  errorServer: Boolean;
  tabVisible : Boolean = false;
  templates: Template[] = [
    {value: '111', viewValue: 'Bleu'},
    {value: '112', viewValue: 'Rouge'},
    {value: '113', viewValue: 'Vert'}
  ];

  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }
  constructor(private kioskService: KioskService,
              private route: ActivatedRoute,
              private stripeTransactionService: StripeTransactionService,
              private userService: UserService,
              private kioskServiceBis: KioskServiceBis,
              private kpiService : KpiService,
              private st: SimpleTimer,
              private transactionService : TransactionService,
              private organisationService : OrganisationService,
              private shareDataService:ShareDataService,
              private router:Router
              ) {
    this.kiosk = new Kiosk();
    this.kiosk.address = {
           city: '',
           postaleCode: '',
           state: '',
           line01: '',
           latitude: '',
           longitude: ''
    };

    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService,transactionService,stripeTransactionService,organisationService,kpiService,shareDataService);
    this.modelDashboard = this.dashboarddata.modelDashboard;
   }



   redirectSelectOrga(){
    this.router.navigateByUrl('/dashboard/selectOrganisation');
  }
    getIdOrganisation(){
       
      this.selectedOrga = this.shareDataService.getItems();
      if (this.selectedOrga !== undefined && this.selectedOrga !== null){
        if(this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null ){
          if(this.selectedOrga.idOrganisation === ""){
            this.redirectSelectOrga();
          }else{
            return this.selectedOrga.idOrganisation;
          }
         
        }
  
      }
  
      return "";
    }
  ngOnInit() {
    this.tabVisible = true;
    var that = this;
    this.idOrganization = this.getIdOrganisation();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        
        this.idKiosk = this.route.snapshot.paramMap.get('id');
        setTimeout(function(){ that.callback(); },Dashboarddata.delay);  
        this.sumTransactionStripeByKioskOrganisation(this.idOrganization, this.route.snapshot.paramMap.get('id'));
        this.kioskByIdAndOrganisation(this.idOrganization, this.route.snapshot.paramMap.get('id'));
        this.getTemplateKiosk();
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  updateTemplateKiosk(){

    var data = {
      idTemplate : this.kiosk.idTemplate,
      id : this.kiosk.idTemplate
    }
    var newId = this.kiosk.idTemplate;
    this.kioskService.setTemplateKiosk(this.kiosk.idKiosk,data).subscribe(
      res => {
        this.selectedTemplate = newId;
      },
      err => {
        this.errorServer = true;
        this.kiosk.idTemplate = this.selectedTemplate; 
      }
    );

    console.log(this.kiosk.idTemplate);
  }
  getTemplateKiosk(){
    this.kioskService.getTemplateKiosk().subscribe(res=>{
      var list = res['template'];
      if (list !== undefined && list !== null){
        this.templates = [];
        list.forEach(element => {
          this.templates.push({
            value : element.id,
            viewValue : element.nameColor
          })
        });
      }
    },
    err => {
      this.errorServer = true;
    });
  }
  kioskByIdAndOrganisation(idO, idK) {
    this.kioskServiceBis.kioskByIdAndOrganisation(idO, idK).subscribe(
      res => {
        this.kiosk = res['kiosk'][0];
        this.selectedTemplate =  this.kiosk.idTemplate;
        console.log("this.kiosk");
        console.log(this.kiosk);
        // this.kiosk.address = {
        //   city: res['kiosk'][0].city,
        //   postaleCode: res['kiosk'][0].postaleCode,
        //   state: res['kiosk'][0].state,
        //   line01: res['kiosk'][0].line01,
        //   latitude: res['kiosk'][0].latitude,
        //   longitude: res['kiosk'][0].longitude,
        //     };
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  sumTransactionStripeByKioskOrganisation(idO, idK) {
    this.stripeTransactionService.sumTransactionStripeByKioskOrganisation(idO, idK).subscribe(
      res => {
        if (res['transactions'].length !== 0) {
            this.sumTransaction = res['transactions'][0].total;
            this.averageTransaction = res['transactions'][0].average;
            this.totalTransaction = res['transactions'][0].totalTransaction;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  callback() {
    if (this.userService.isLoggedIn()) {           
      this.dashboarddata.formatDataDashboardAll(this);
    } else {
      this.st.delTimer('5sec');
    }
  }


}
