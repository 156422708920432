import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
//import { Router } from "@angular/router";
import { UserService } from '../shared/user.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-comfirm-account',
  templateUrl: './comfirm-account.component.html',
  styleUrls: ['./comfirm-account.component.css']
})
export class ComfirmAccountComponent implements OnInit {
  userDetails = {
    fullName: ''
  };
  // constructor(private route: ActivatedRoute, private userService : UserService) { }
  constructor(private userService: UserService, private route: ActivatedRoute, private router: Router) { }

  ngOnDestroy(){
 
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  
  ngAfterViewInit() {
    // this.loadExternalScript('assets/js/jquery.js');
    this.loadExternalScript('assets/js/jquery.calendario.js');
    this.loadExternalScript('assets/demos/nonprofit/js/events.js');
    this.loadExternalScript('assets/js/functions.js');
  }

  ngOnInit() {


    
    if (this.userService.isLoggedIn())
        this.router.navigateByUrl('/dashboard');
     this.userService.activeUserAccount(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
         this.userDetails.fullName = res['user'].fullName;

         console.log(res);
      },
      err => {
        console.log(err);
      }
     );

  }


}
