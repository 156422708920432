import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { OrganisationService } from './../../../shared/organisation.service';
import { Organisation } from './../../../shared/organisation.model';
import { User } from './../../../shared/user.model';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { AngularIbanModule } from 'angular-iban';
import { UserService } from './../../../shared/user.service';
import { Router } from '@angular/router';

const URL = 'http://localhost:3002/api/organisation/upload';


@Component({
  selector: 'app-create-organisation-admin',
  templateUrl: './create-organisation-admin.component.html',
  styleUrls: ['./create-organisation-admin.component.css']
})
export class CreateOrganisationAdminComponent implements OnInit {

  organisation: Organisation;
  user: any;
  form: FormGroup;
  fb: FormBuilder;
  iban: AngularIbanModule;
  valueIban: String;
  confirmRib: '';
  testIban: '';
  isSameIban: Boolean;

  linkDocument01 = '';
  linkDocument02 = '';
  isIdentityFileValide: Boolean;

  statusUpdatedGood: Boolean = false;
  statusUpdatedAlert: Boolean = false;


  public uploaderVerso: FileUploader = new FileUploader({
      url: URL,
      itemAlias: 'photo',
      authToken: `Bearer ${this.userService.getToken()}`
    });
  public uploaderRecto: FileUploader = new FileUploader({
      url: URL,
      itemAlias: 'photo',
      authToken: `Bearer ${this.userService.getToken()}`
    });

  constructor(private userService: UserService, private organisationService: OrganisationService, fb: FormBuilder,
    private angularIbanModule: AngularIbanModule, private router: Router) {

    this.form = fb.group({
      fullName: ['', Validators.required],
      email: ['', Validators.required],
      organisationName: ['', Validators.required],
      description: ['', Validators.required],
      address_line1: ['', Validators.required],
      address_postal_code: ['', Validators.required],
      address_city: ['', Validators.required],
      address_state: ['', Validators.required],
      rib: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      dobindividual: ['', Validators.required],
      });

  }

  ngOnInit() {
    this.uploaderVerso.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploaderRecto.onAfterAddingFile = (file) => { file.withCredentials = false; };
  }

  onSubmit() {

    /**
     * If the form is valid ( all informations valid )
     */
    if (this.form.status === 'VALID') {
    /**
     * Define information for User
     */
    const user = {
    email: this.form.value.email,
    fullName: this.form.value.fullName
    };
    this.form.value.user = user;
    /**
     * Create User
     */
    this.userService.postUserAdmin(this.form.value).subscribe(
      res => {
        this.user = res;
        /**
         * Create organisation
         */
        setTimeout(() => this.createOrganisation(this.form.value), 1000);
      },
      err => {
        this.statusUpdatedAlert = true;
        setTimeout(() => this.statusUpdatedAlert = false, 4000);
      }
    );
    }
}

createOrganisation(value) {
  this.uploadDocument();
    /**
     * created Organisation
     */
    this.organisationService.postOrganisationBiss(value).subscribe(
    res => {
    this.statusUpdatedGood = true;
    setTimeout(() => this.statusUpdatedGood = false, 4000);
    this.router.navigateByUrl('/dashboard/admin/organisation');
    },
    err => {
    this.statusUpdatedAlert = true;
    setTimeout(() => this.statusUpdatedAlert = false, 4000);
    }
    );

}

/**
 * Upload all document for control identity
 */
uploadDocument() {
  const idCardVerso = this.uploaderVerso.queue[this.uploaderVerso.queue.length - 1];
  const idCardRecto = this.uploaderRecto.queue[this.uploaderRecto.queue.length - 1];
  /**
   * if scan card verso is defined
   */
  if (idCardVerso === undefined || idCardVerso === null ) {
      this.isIdentityFileValide = false;
  } else {
    /**
     * Define name of the file
     */
    idCardVerso.file.name = 'v' + this.user._id;
    /*
    * Upload scan Card Verso
    */
    this.uploaderVerso.onCompleteItem = (item: any, response: any, status: any, headers: any) => {};
    this.uploaderVerso.uploadItem(idCardVerso);
        }
  /**
   * if scan card recto is defined
   */
  if (idCardRecto) {
   /**
    * Define name of the file
    */
    idCardRecto.file.name = 'r' + this.user._id;
   /*
    * Upload scan Card Recto
    */
    this.uploaderRecto.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    };
    this.uploaderRecto.uploadItem(idCardRecto);
  }
}
/**
 * Control if both IBAN is same
 */
isSameIbanFunc() {
    this.confirmRib = this.form.get('rib').value;
    if (this.confirmRib === this.testIban ) {
    this.isSameIban = true;
    } else {
    this.isSameIban = false;
    }
}

}
