import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CampaignService } from './../../../shared/campaign.service';
import { Campaign } from './../../../shared/campaign.model';

@Component({
  selector: 'app-readcampaign',
  templateUrl: './readcampaign.component.html',
  styleUrls: ['./readcampaign.component.css']
})
export class ReadcampaignComponent implements OnInit {
  campaign: Campaign;
  sumAmount: any = 0;
  sumNet: any = 0;
  sumFee: any = 0;
  validMsg;
  errorMsg;
  constructor(private route: ActivatedRoute, private campaignService: CampaignService) {
    this.campaign = new Campaign();
  }

  ngOnInit() {

    this.campaignService.getCampaignAndTransactionAggregate(this.route.snapshot.paramMap.get('id')).subscribe(
      res => {
        console.log(res['campaign']);
        this.campaign = res['campaign'][0];
        this.calculSumCampaign(res['campaign'][0].transacCampaing);
      },
      err => {
        this.errorMsg = true;
      });

    // this.campaignService.getCampaignById(this.route.snapshot.paramMap.get('id')).subscribe(
    // res => {
    //   this.campaign = res['campaign'];
    // },
    // err => {
    //   this.errorMsg = true;
    // });
  }

  calculSumCampaign(transactions) {
    console.log(transactions);  
    for (let i = 0; i < transactions.length; i++) {
      
      if (transactions[i].amount !== undefined && !isNaN(Number(transactions[i].amount))) {
        this.sumAmount += Number(transactions[i].amount);
      }

      if (transactions[i].net !== undefined && !isNaN(Number(transactions[i].net))) {
        this.sumNet += Number(transactions[i].net);
      }

      if (transactions[i].fee !== undefined && !isNaN(Number(transactions[i].fee))) {
        this.sumFee += Number(transactions[i].fee);
      }


    }
    this.sumAmount = this.sumAmount.toFixed(2);
    this.sumNet = this.sumNet.toFixed(2);
    this.sumFee = this.sumFee.toFixed(2);
  }

  updateStatusCampaign(_id, status) {
    const campaign = {
      _id: _id,
      status: status
    };
    this.campaignService.updateStatus(campaign).subscribe(
      res => {
        console.log(res);
        this.campaign.actif = status;
        // this.campaign.actif = res['campaign'].actif;
      },
      err => {
        this.errorMsg = true;
      });
  }
}
