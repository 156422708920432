import { Organisation } from './../../shared/organisation.model';
import { Kiosk } from './../../shared/kiosk.model';
import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, Form, FormControl } from '@angular/forms';
import { OrganisationService } from './../../shared/organisation.service';
import { KioskService } from './../../shared/kiosk.service';
import { PagerService } from './pager.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-kioskd-admin',
  templateUrl: './kioskd-admin.component.html',
  styleUrls: ['./kioskd-admin.component.css']
})
export class KioskdAdminComponent implements OnInit {

  islistKiosk: Boolean = true;
  isOrganisationSelected: Boolean = false;
  form: FormGroup;
  fb: FormBuilder;
  actionSubmit: String = 'CREATE';
  organisation = [];
  organisationCurrent: Organisation;
  isSuccessMessage: Boolean = false;
  kiosksArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];

  constructor(private organisationService: OrganisationService, private kioskService: KioskService, fb: FormBuilder,
              private pagerService: PagerService) {
    this.form = fb.group({
      id: [''],
      type: ['', Validators.required],
      supervisor: ['', Validators.required],
      line01: ['', Validators.required],
      city: ['', Validators.required],
      postaleCode: ['', Validators.required],
      state: ['', Validators.required],
      location: ['', Validators.required],
      description: ['', Validators.required]
    });
  }


  ngOnInit() {
    this.listOrganisations();
    this.list();
  }

  onSubmit() {
    /**
     * Define if organisation selected
     */
    this.form.value.isOrganisationSelected = this.isOrganisationSelected;
    /**
     * If organisation selected for kiosk
     */
    if (this.isOrganisationSelected === true) {
        this.form.value.organisationSelected = this.organisationCurrent;
    }

    /**
     * If the form is valid ( all informations valid )
     */
    if (this.form.status === 'VALID') {
      /**
     * If the action is creation of new kiosk
     */
      if (this.actionSubmit === 'CREATE') {
            // Create new kioks
            this.kioskService.addKiosk(this.form.value).subscribe(
              res => {
                this.form.reset();
                this.islistKiosk = true;
                this.isSuccessMessage = true;
                setTimeout(() => this.isSuccessMessage = false, 4000);
                this.list();

              },
              err => {

              }
            );
            /**
             * If the action is updating of kiosk
             */
        } else if (this.actionSubmit === 'UPDATE') {

            // if (this.isOrganisationSelected === true){
              // this.form.addControl('organisation', new FormControl(''));
              // this.form.controls['organisation'].setValue(this.organisationCurrent);
            // }

            this.kioskService.update(this.form.value).subscribe(
              res => {

              },
              err => {

              }

            );

        }


        //Update kioks


    }
  }

  create() {
    this.islistKiosk = false;
    this.actionSubmit = 'CREATE';
  }

  list() {
    this.islistKiosk = true;
    this.kioskService.getKioks().subscribe(
      res => {
        this.kiosksArray = res['kiosk'];

        // set items to json response
        this.allItems = res['kiosk'];
        // initialize to page 1
        this.setPage(1);

      },
      err => {

      }
    );
  }

  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  update(kiosk) {
    console.log(this.organisationCurrent);
    this.islistKiosk = false;
    this.actionSubmit = 'UPDATE';
    if (kiosk.organisationName !== 'Aucune Organisation') {
        this.isOrganisationSelected = true;
        // Get organisation
        this.organisationCurrent = kiosk.organisation;
    }
    this.form.value.organisationSelected = this.organisationCurrent;

    this.form.controls['id'].setValue(kiosk._id);
    this.form.controls['type'].setValue(kiosk.type);
    this.form.controls['supervisor'].setValue(kiosk.supervisor);
    this.form.controls['line01'].setValue(kiosk.address.line01);
    this.form.controls['city'].setValue(kiosk.address.city);
    this.form.controls['postaleCode'].setValue(kiosk.address.postaleCode);
    this.form.controls['state'].setValue(kiosk.address.state);
    this.form.controls['location'].setValue(kiosk.location);
    this.form.controls['description'].setValue(kiosk.description);

  }

  delete() {
    console.log("je suis dans le delete");
  }

  listOrganisations() {
    this.organisationService.getAllOrganisation().subscribe(
      res => {

        this.organisation = res['organisation'];

      },
      err => {

      }
      );
  }

  addOrganisation(orgnaisation) {

    this.isOrganisationSelected = true;
    this.organisationCurrent = orgnaisation;
    // this.organisationCurrent.email = orgnaisation.accountStripe.email;
  }

  deleteSelectedOrganisation() {
    this.organisationCurrent.organisationName = '';
    this.organisationCurrent.accountStripe.email = '';
    this.isOrganisationSelected = false;
  }

  updateSelectedOrganisation() {
  }


  searchKiosk(searchValue: string) {
    var kiosksArray =  this.kiosksArray.filter(x => x.id_kiosk.toUpperCase().includes(searchValue.toUpperCase()) ||
                                               x.organisationName.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = kiosksArray;
    this.setPage(1);

  }

}
