import { Pipe ,PipeTransform} from '@angular/core';

class formatPipe {
    format(value: number, decimalDelimiter: string, decimalLength: number, chunkLength: number, chunkDelimiter: string, currencySign: string, flagCompress: Boolean): string {
        //value /= 100;
        try {

            if (flagCompress) {
                if (!isNaN(value)) {

                    if (value >= 1000000){
                        value = value / 10000;
                        currencySign = ' To' + currencySign;
                    }
                        
                    else if (value >= 100000)
                           { value = value / 1000;
                            currencySign = ' K' + currencySign;}

                }


               
            }

            let num = value.toString();
            if (value.toFixed !== undefined) {
                num = value.toFixed(Math.max(0, ~~decimalLength));
            } else {

                if (flagCompress) {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                } else {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                }
                console.log("value : " + num);
            }


            let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter) + currencySign;
        } catch (error) {
            console.log("error formatPipe");
            console.log(error);
            return value.toString();
        }
    }
}


@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormat {
    transform(value: number,
        compress : Boolean = false,
        currencySign: string = '€ ',
        decimalLength: number = 2,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, compress);
    }
}
@Pipe({
    name: 'currencyFormatWithoutDecimal'
})
export class CurrencyFormatWithoutDecimal {
    transform(value: number,
        compress : Boolean = false,
        currencySign: string = '€ ',
        decimalLength: number = 0,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3,
        ): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, compress);
    }
}


@Pipe({
    name: 'currencyFormatWithoutDecimalCompress'
})
export class CurrencyFormatWithoutDecimalCompress implements PipeTransform{

    transform(value: number,
        currencySign: string = '€ ',
        decimalLength: number = 0,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, true);
    }
}


@Pipe({
    name: 'currencyFormatDecimal'
})

export class CurrencyFormatDecimal {

    transform(value: number,
        compress : Boolean = false,
        currencySign: string = '€ ',
        decimalLength: number = 2,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, compress);

    }
}


@Pipe({
    name: 'numberFormatDecimalFr'
})
export class NumberFormatDecimalFr {
    transform(value: number,
        compress : Boolean = false,
        currencySign: string = '',
        decimalLength: number = 2,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, compress);
    }
}
@Pipe({
    name: 'numberFormatFr'
})
export class NumberFormatFr {
    transform(value: number,
        compress : Boolean = false,
        currencySign: string = '',
        decimalLength: number = 0,
        chunkDelimiter: string = ' ',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        return new formatPipe().format(value, decimalDelimiter, decimalLength, chunkLength, chunkDelimiter, currencySign, compress);
    }
}
