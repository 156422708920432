import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationService } from '../shared/organisation.service';
import { Organisation } from '../shared/organisation.model';
import { Campaign } from '../shared/campaign.model';
import { CampaignService } from '../shared/campaign.service';

@Component({
  selector: 'app-donation-card',
  templateUrl: './donation-card.component.html',
  styleUrls: ['./donation-card.component.css']
})
export class DonationCardComponent implements OnInit {

  asso: any;
  organization: Organisation;
  city: String;
  line1: String;
  campaigns: any;
  nbCampaign: any;
  constructor(private route: ActivatedRoute, private organisationService: OrganisationService, private campaignService: CampaignService) {
    this.organization = new Organisation();
   }

  ngOnInit() {

  this.asso =   this.route.snapshot.paramMap.get('asso');
  this.getOrganisationByUserId(this.asso);

  }


  getOrganisationByUserId(id) {
    
    this.organisationService.organizationAgregateCampaign(id).subscribe(
      res => {
        console.log(res);
        this.organization = res['organisation'];
        this.city = res['organisation'].address.city;
        this.line1 = res['organisation'].address.line1;
        this.donCampaignOrganization(res['organisation']._id);
      },
      err => {
          console.log(err);
      }
    );
  }

  donCampaignOrganization(id) {
    this.campaignService.donCampaignOrganization(id).subscribe(
      res => {
        this.campaigns = res['campaign'];
        this.nbCampaign = res['campaign'].length;
        console.log(this.campaigns.length);
      },
      err => {
          console.log(err);
      });
  }




}

