import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../../../assets/canvasjs.min';
import { StripeTransactionService } from '../../../shared/stripeTransaction.service';
import { StripeTransaction } from '../../../shared/stripeTransaction.model';
import { UserService } from '../../../shared/user.service';
import { TransactionService } from '../../../shared/transaction.service';
import { PagerService } from '../../../shared/pager.service';
import { OrganisationService } from '../../../shared/organisation.service';
import { formatter } from '../../../shared/formatter';
import { Dashboarddata } from '../../../shared/dashaboardata';
import { KpiService } from '../../../shared/kpi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ShareDataService } from '../../../shared/shareData.service';
var _ = require('lodash');
@Component({
  selector: 'app-donator-list',
  templateUrl: './donator-list.component.html',
  styleUrls: ['./donator-list.component.css']
})
export class DonatorListComponent implements OnInit {

  formartter: formatter;
  dashboarddata: Dashboarddata;
  timerId: string;
  idOrganization: String;
  modelDashboard: any;

  loading: String = "none";
  averageTransaction: number = 0;
  nbDonorWhitoutEmail: number = 0;
  nbDonationWithoutEmail: number = 0;
  nbDonorWhithEmail: number = 0;
  nbTransactionWhithEmail: number = 0;
  totalAmountTransactionWithEmail: number = 0;
  totalAmountTransactionWithoutEmail: number = 0;
  errorServer;

  donorsArray = [];
  // array of all items to be paged
  private allItems: any[];
  // pager object
  pager: any = {};
  // paged items
  pagedItems: any[];
  listTransaction: Boolean;
  unKnowDonor: any = {
    _id: '',
    totalTransaction: 0,
    amounttotal: 0
  };

  selectedOrga: any = {
    idOrganisation: '',
    organisationName: '',
  }


  currentTranction: StripeTransaction;

  constructor(private stripeTransactionService: StripeTransactionService,
    private userService: UserService,
    private transactionService: TransactionService,
    private pagerService: PagerService,
    private kpiService: KpiService,
    private shareDataService:ShareDataService,
    private organisationService: OrganisationService,
    private router: Router) {
    this.currentTranction = new StripeTransaction();


    this.formartter = new formatter();
    this.dashboarddata = new Dashboarddata(userService, transactionService, stripeTransactionService, organisationService, kpiService,shareDataService);
    this.modelDashboard = this.dashboarddata.modelDashboard;
  }

redirectSelectOrga(){
  this.router.navigateByUrl('/dashboard/selectOrganisation');
}
  getIdOrganisation(){
     
    this.selectedOrga = this.shareDataService.getItems();
    if (this.selectedOrga !== undefined && this.selectedOrga !== null){
      if(this.selectedOrga.idOrganisation !== undefined && this.selectedOrga.idOrganisation !== null ){
        if(this.selectedOrga.idOrganisation === ""){
          this.redirectSelectOrga();
        }else{
          return this.selectedOrga.idOrganisation;
        }
       
      }

    }

    return "";
  }
  ngOnInit() {
    var that = this;
    this.idOrganization = this.getIdOrganisation();
    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.getSumTranstionByOrganisation(this.idOrganization );
        this.getStripeEmailDonorByOrganisation(this.idOrganization );
        this.nbStripeDonorByOrganisationByEmailNull(this.idOrganization );
        this.idOrganization = this.idOrganization ;
        setTimeout(function(){ that.callback(); }, Dashboarddata.delay);   
      },
      err => {
        this.errorServer = true;
      }
    );
  }


  setPage(page: number) {
    // get pager object from service
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    // get current page of items
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  searchDonor(searchValue: string) {
    const donorsArray = this.donorsArray.filter(x => x._id.toUpperCase().includes(searchValue.toUpperCase()));
    this.allItems = donorsArray;
    this.setPage(1);
  }

  getSumTranstionByOrganisation(id) {
    this.stripeTransactionService.sumTransactionStripeByOrganisation(id).subscribe(
      res => {
        if (res['transactions'].length !== 0) {
          this.averageTransaction = res['transactions'][0].average.toFixed(2);
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }



  getNbDonorByOrganisation(id) {
    this.transactionService.getNbDonorByOrganisation(id).subscribe(
      res => {
        if (res['donors'].length !== 0) {
          // this.nbDonorWhitoutEmail = res['donors'].length;
        }

      },
      err => {
        this.errorServer = true;
      }
    );
  }

  nbStripeDonorByOrganisationByEmailNull(id) {
    var that = this;
    this.nbDonorWhitoutEmail = 0;
    this.nbDonationWithoutEmail = 0;
    this.stripeTransactionService.nbStripeDonorByOrganisationByEmailNull(id).subscribe(
      res => {
        if (res['donors'].length !== 0) {
          res['donors'].forEach(element => {
            if (element._id === null || element._id === "null") {
              that.nbDonorWhitoutEmail++;
              that.nbDonationWithoutEmail = that.nbDonationWithoutEmail + element.totalTransaction;
            }
          });
          //this.nbDonorWhitoutEmail = res['donors'][0].totalTransaction;
        }
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  getStripeEmailDonorByOrganisation(id) {
    this.loading = "block";
    this.stripeTransactionService.getStripeEmailDonorByOrganisation(id).subscribe(
      res => {
        const donator = this.findUnknowDonator(res['donors']);
        this.nbDonorWhithEmail = donator.length;
        this.donorsArray = donator;
        this.allItems = donator;
        this.loading = "none";
        this.donorsArray.reverse();

        if (this.allItems.length !== 0) {
          this.listTransaction = true;
        } else {
          this.listTransaction = false;
        }
        this.setPage(1);
      },
      err => {
        this.errorServer = true;
      }
    );
  }

  findUnknowDonator(donors) {

    const arrayDonator = [];
    this.unKnowDonor = {
      _id: 'null',
      totalTransaction: 0,
      amounttotal: 0
    };
    this.nbTransactionWhithEmail = 0;
    for (let i = 0; i < donors.length; i++) {
      const _id = donors[i]._id;
      if (_id !== 'null' && _id !== null) {
        arrayDonator.push(donors[i]);
        this.nbTransactionWhithEmail = this.nbTransactionWhithEmail + donors[i].totalTransaction;
        this.totalAmountTransactionWithEmail = this.totalAmountTransactionWithEmail + donors[i].amounttotal;
      } else if (_id === 'null' || _id === null) {
        this.unKnowDonor.totalTransaction = this.unKnowDonor.totalTransaction + donors[i].totalTransaction;
        this.totalAmountTransactionWithoutEmail = this.totalAmountTransactionWithoutEmail + donors[i].amounttotal;
        this.unKnowDonor.amounttotal = this.totalAmountTransactionWithoutEmail;

      }
    }

    return arrayDonator;
  }

  callback() {
    if (this.userService.isLoggedIn()) {
      this.dashboarddata.formatDataDashboardAll(this);
    } else {

    }
  }


}
