import { Organisation } from './../../shared/organisation.model';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { OrganisationService } from '../../shared/organisation.service';
import { NavigationEnd, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
import { ShareDataService } from '../../shared/shareData.service';

@Component({
  selector: 'app-dashboardmenu',
  templateUrl: './dashboardmenu.component.html',
  styleUrls: ['./dashboardmenu.component.css']
})
export class DashboardmenuComponent implements OnInit {
  routerSubscription: any;
  userDetails = {
    organisation: {
                organisationName : ''
                  }
  };
  selectedOrga :any = {
    idOrganisation: '',
    organisationName: '',
};
idOrganization: String;
  organisationName: String;
  today: number = Date.now();
  CURRENT_PAGE : String;
  constructor(private userService: UserService, private organisationService: OrganisationService, private router: Router,
    private shareDataService:ShareDataService) {
    this.router = router;
   }
   public loadExternalScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  
  ngAfterViewInit() {
    // this.loadExternalScript('../../assets/js/jquery.js');
    // this.loadExternalScript('../../assets/js/jquery.calendario.js');
    // this.loadExternalScript('../../assets/demos/nonprofit/js/events.js');
    //  this.loadExternalScript('../../assets/js/functions.js');+
    //this.loadExternalScript('../../assets/js/plugins.js');
    //this.loadExternalScript('../../assets/js/functions.js');
  }

 /**
   * @description when we navigate from one page to another `scope of js funtions`
   * finished so we have to call it again.
   */
 

  ngOnDestroy() {
    
  }

  ngOnInit() {
   // this.selectedOrga = this.shareDataService.getItems();
    this.idOrganization = this.selectedOrga.idOrganisation;

    this.shareDataService.currentMessage.subscribe(selectOrga => this.selectedOrga = selectOrga);

    this.userService.getUserProfileAdm().subscribe(
      res => {
        this.userDetails = res['user'];
        this.getOrganisationByUserId(res['user'].idOrganisation);
        // this.userDetails.organisation = res['user'].organisation;
        // console.log(this.userDetails);
      },
      err => {
        console.log(err);
      }
    );
  }

  onLogout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

  getOrganisationByUserId(id) {
      this.organisationService.getOrganisationById(id).subscribe(
        res => {
            this.organisationName = res['organisation'].organisationName;
        },
        err => {
            this.organisationName = err.error.message;
        }
      );
    }
}
