
export class formatter {

    month : Array<string>;
    monthShort : Array<string>;
    days : Array<string>;
    daysShort : Array<string>;

    constructor() {
        this.month = this.setMonth();
        this.monthShort = this.setMonthShort();
        this.days = this.setDay();
        this.daysShort = this.setDaysShort();


      }


      formtDateText (sPrefix,oDate,sFormat){

        var txtFormat = sPrefix;
        var spaceStart = " ";
        var space = " ";

        if(txtFormat == "")
                 spaceStart = "";

        switch(sFormat){
            case "DDddMMMAAAA" :
                txtFormat = txtFormat.concat(spaceStart, oDate.dayWeek,space, oDate.dayMonth,space,oDate.monthShort,space,oDate.years );
            break;
            case "MMMAAAA" :                
                txtFormat = txtFormat.concat(spaceStart,oDate.monthShort,space,oDate.years );
            break;

            case "AAAA" :                
            txtFormat = txtFormat.concat(spaceStart,oDate.years );
        break;

        }

        return txtFormat;

      }


      setDay(){
        
        let days = [];
        days.push('Lundi');
        days.push('Mardi');
        days.push('Mercredi');
        days.push('Jeudi');
        days.push('Vendredi');
        days.push('Samedi');
        days.push('Dimanche');
        return days;

    }

    setDaysShort(){
        
        let days = [];
        days.push('Lundi');
        days.push('Mardi');
        days.push('Mercredi');
        days.push('Jeudi');
        days.push('Vendredi');
        days.push('Samedi');
        days.push('Dimanche');
        return days;

    }

    setMonth(){
        
        let months = [];
        months.push('Janvier');
        months.push('Février');
        months.push('Mars');
        months.push('Avril');
        months.push('Mai');
        months.push('Juin');
        months.push('Juillet');
        months.push('Aout');
        months.push('Septembre');
        months.push('Octobre');
        months.push('Novembre');
        months.push('Décembre');

        return months;

    }

    setMonthShort(){
        let monthsShort = [];
        monthsShort.push('Janv.');
        monthsShort.push('Févr.');
        monthsShort.push('Mars');
        monthsShort.push('Avr.');
        monthsShort.push('Mai');
        monthsShort.push('Juin');
        monthsShort.push('Juill.');
        monthsShort.push('Aout');
        monthsShort.push('Sept.');
        monthsShort.push('Oct.');
        monthsShort.push('Nov.');
        monthsShort.push('Déc.');

        return monthsShort;

    }

    format(flagCompress,value,currencySign,decimalLength,decimalDelimiter,chunkLength,chunkDelimiter): string {
        //value /= 100;
        try {

            if (flagCompress) {
                if (!isNaN(value)) {

                    if (value >= 1000000){
                        value = value / 10000;
                        currencySign = ' To' + currencySign;
                    }
                        
                    else if (value >= 100000)
                           { value = value / 1000;
                            currencySign = ' K' + currencySign;}

                }


               
            }

            let num = value.toString();
            if (value.toFixed !== undefined) {
                num = value.toFixed(Math.max(0, ~~decimalLength));
            } else {

                if (flagCompress) {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                } else {
                    num = parseFloat(num).toFixed(Math.max(0, ~~decimalLength));
                }
                console.log("value : " + num);
            }


            let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
            return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter) + currencySign;
        } catch (error) {
            console.log("error formatPipe");
            console.log(error);
            return value.toString();
        }
    }


    formatDate(date) {
        let monthDate = date.getMonth() + 1;
        let day = date.getDate();
    
        if (monthDate < 10) {
          monthDate = '0' + monthDate;
        }
    
        if (day < 10) {
          day = '0' + day;
        }
        return day + '/' + monthDate + '/' + date.getFullYear();
      }
}



